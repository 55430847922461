/**
 * Notes:
 * 
 * Hex color code for transparency:
 *	https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
 * 
 * -webkit-:
 *	The -webkit- prefix is needed for Safari and Chrome when using transitions,
 *	transforms, animation, gradients, calc, flexbox, and columns. For
 *	border-radius, box-shadow, border-image, and text-shadow it's not really
 *	necessary unless you want to cover older browsers like Safari 5.0.
 */

	/* Clear All Default Styles */
	@namespace svg "http://www.w3.org/2000/svg";
	body {
		all: initial;
		*:not(svg|*) {
			all: unset;
		}
		overflow-x: hidden;
		transform-style: preserve-3d;
	}



/*!*--- Style guidelines ---**/

	/* Color */
	$color-lanlv: #205373;			// 蓝绿
	$color-lvsongshi: #4BCAD1;	// 绿松石
	$color-xihongshi: #FD6041;	// 西红柿
	$color-xiangyabai: #FAF5EF;	// 象牙白
	$color-gray1-33: #333333;		// 墨（灰1-#3）
	$color-gray2-66: #666666;		// 灰2-#6
	$color-gray3-99: #999999;		// 灰3-#9
	$color-gray4-CC: #CCCCCC;		// 灰4-#C
	$color-gray5-E3: #E3E3E3;		// 灰5-#E3
	$color-gray6-F0: #F0F0F0;		// 灰6-#F0
	$color-bg-light: #FFFFFF;		// 亮背景
	$color-bg-dark: #232424;		// 暗背景
	$color-black: #000000;			// 黑
	$color-white: #FFFFFF;			// 白

	/* Timing */
	$time-xs: 65ms;
	$time-s: 135ms;
	$time-m: 270ms;
	$time-l: 540ms;
	$time-xl: 800ms;
	$time-sec: 1000ms;
	$time-inf: 100000ms;

	/* Font family */
	@font-face { font-family: "DelaGothicOne"; src: url("./assets/basic/fonts/DelaGothicOne.ttf"); }
	@font-face {
		font-family: "DelaGothicOne";
		src:
			local("DelaGothicOne"),
			url("./assets/basic/fonts/DelaGothicOne.woff2") format("woff2"),
			url("./assets/basic/fonts/DelaGothicOne.woff") format("woff"),
			url("./assets/basic/fonts/DelaGothicOne.ttf") format("opentype");
		}
	@font-face {
		font-family: "SukhumvitSet";
		src:
			local("SukhumvitSet-Text"),
			url("./assets/basic/fonts/SukhumvitSet-Text.woff2") format("woff2"),
			url("./assets/basic/fonts/SukhumvitSet-Text.woff") format("woff"),
			url("./assets/basic/fonts/SukhumvitSet-Text.ttf") format("opentype");
		}
	@font-face {
		font-family: "SukhumvitSet-Light";
		src:
			local("SukhumvitSet-Light"),
			url("./assets/basic/fonts/SukhumvitSet-Light.woff2") format("woff2"),
			url("./assets/basic/fonts/SukhumvitSet-Light.woff") format("woff"),
			url("./assets/basic/fonts/SukhumvitSet-Light.ttf") format("opentype");
		}
	@font-face {
		font-family: "SukhumvitSet-Bold";
		src:
			local("SukhumvitSet-Bold"),
			url("./assets/basic/fonts/SukhumvitSet-Bold.woff2") format("woff2"),
			url("./assets/basic/fonts/SukhumvitSet-Bold.woff") format("woff"),
			url("./assets/basic/fonts/SukhumvitSet-Bold.ttf") format("opentype");
		}
	@font-face {
		font-family: "BiaoXiaoZhiWuJieHei";
		src:
			local("BiaoXiaoZhiWuJieHei"),
			url("./assets/basic/fonts/BiaoXiaoZhiWuJieHei.woff2") format("woff2"),
			url("./assets/basic/fonts/BiaoXiaoZhiWuJieHei.woff") format("woff"),
			url("./assets/basic/fonts/BiaoXiaoZhiWuJieHei.ttf") format("opentype");
		}
	@font-face {
		font-family: "HanSansSC";
		src:
			local("SourceHanSansSC-Regular"),
			url("./assets/basic/fonts/HanSansSC-Regular.woff2") format("woff2"),
			url("./assets/basic/fonts/HanSansSC-Regular.woff") format("woff"),
			url("./assets/basic/fonts/HanSansSC-Regular.otf") format("opentype");
		}
	@font-face {
		font-family: "HanSansSC-Bold";
		src:
			local("SourceHanSansSC-Bold"),
			url("./assets/basic/fonts/HanSansSC-Bold.woff2") format("woff2"),
			url("./assets/basic/fonts/HanSansSC-Bold.woff") format("woff"),
			url("./assets/basic/fonts/HanSansSC-Bold.otf") format("opentype");
		}
	@font-face {
		font-family: "EmojiOneBW";
		src:
			local("EmojiOneBW"),
			url("./assets/basic/fonts/EmojiOneBW.woff2") format("woff2"),
			url("./assets/basic/fonts/EmojiOneBW.woff") format("woff"),
			url("./assets/basic/fonts/EmojiOneBW.otf") format("opentype");
		}
	$font-serif-en: "DelaGothicOne", Georgia, Times, "Times New Roman", serif;
	$font-sans-serif-en: "SukhumvitSet-Text", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
	$font-sans-serif-light-en: "SukhumvitSet-Light", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
	$font-sans-serif-bold-en: "SukhumvitSet-Bold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
	$font-serif-cn: "BiaoXiaoZhiWuJieHei", "Noto Sans TC", "微软正黑体", sans-serif;
	$font-sans-serif-cn: "HanSansSC", "Noto Sans TC", "微软正黑体", sans-serif;
	$font-sans-serif-bold-cn: "HanSansSC-Bold", "Noto Sans TC", "微軟正黑體", sans-serif;
	$font-emoji: "EmojiOneBW";

	/* Font size & lineheight */
	$font-default: 18px;
		$lineheight-default: 1.8 * $font-default;
	$font-hint: 16px;
		$lineheight-hint: 1.5 * $font-hint;
	$font-h1: 40px;
		$lineheight-h1: 1.2 * $font-h1;
	$font-h2: 24px;
		$lineheight-h2: 1.5 * $font-h2;
	$font-display: 80px;
		$lineheight-display: 1 * $font-display;

	/* Spacing */
	$spacing-1: 2px;
	$spacing-2: 4px;
	$spacing-3: 8px;
	$spacing-4: 16px;
	$spacing-5: 24px;
	$spacing-6: 32px;
	$spacing-7: 40px;
	$spacing-8: 80px;
	$spacing-9: 120px;
	$spacing-10: 160px;

	/* Layering */
	$z-top: 100;
	$z-bg: -100;
	.zlift { z-index: 1; } /* z-index lifting tool. */
	.clickthrough { pointer-events: none; }

	/* Border radius */
	$radius-l: 40px;
	$radius-m: 8px;
	$radius-s: 4px;
	$radius-xs: 2px;
	$radius-circle: 100%;
	$radius-inf: 1000px;

	/* Border width */
	$border-width-s: 1px;
	$border-width-m: 2px;
	$border-width-l: 4px;
	$border-width-xl: 8px;

	/* Standard margin */
	$block-reel: 16px 0px;
	$case-margin-left: 16px;
	$scroll-margin: 40px;

	/* Drop shadow */
	$image-shadow-filter:
		drop-shadow(0px 0px 0.25px rgba(51, 51, 51, 0.25))
		drop-shadow(0px 2px 2px rgba(153, 153, 153, 0.15))
		drop-shadow(0px 0px 8x rgba(204, 204, 204, 0.25));
	$image-shadow-boxshadow:
		0px 0px 0.5px rgba(51, 51, 51, 0.3),
		0px 2px 3.5px rgba(153, 153, 153, 0.2),
		0px 0px 10px rgba(204, 204, 204, 0.3);
			// 墨，灰3-#9，灰4-#C

	/* Clip Path */
	@mixin clip-path($path: none) {
		-webkit-clip-path: $path;
		clip-path: $path;
	}

	/* Opacity */
	$opacity-35: 0.35;
	$opacity-12: 0.12;

	/* Position */
	@mixin position-center-absolute {
		position: absolute;
		transform: translate(-50%,-50%);
		left: 50%;
		top: 50%;
	}

	/* Ratio */
	//$ratio-default: 60%;

	/* Scaling */
	$scaling-xs: 1.35;
	$scaling-s: 1.2;
	$scaling-m: 1.12;
	$scaling-l: 1.02;

	/* Animation */

	@mixin transition($transition: none) {
		-webkit-transition: $transition;
		-moz-transition: $transition;
		-ms-transition: $transition;
		-o-transition: $transition;
		transition: $transition;
	}

	$transition-bouncer-s: cubic-bezier(0.4, 4, 0.1, 0.4);
	$transition-bouncer-s-time: 4;	// TODO: side-by-side test of these time ratios
	$transition-bouncer-l: cubic-bezier(0.6, 2.5, 0.4, 0.6);
	$transition-bouncer-l-time: 1.5;
	$transition-bouncer-xl: cubic-bezier(0.5, 1.7, 0.4, 0.8);
	$transition-bouncer-xl-time: 1.35;
	$transition-bouncer-gentle: cubic-bezier(0.6, 1.8, 0.6, 0.8);

	$transition-color-in: color $time-xs ease;
	$transition-color-out: color $time-l ease-in-out;
	$transition-bgcolor-in: background-color $time-xs ease;
	$transition-bgcolor-out: background-color $time-l ease-in-out;
	// $transition-opacity-gentle-in: opacity $time-m ease-in;
	// $transition-opacity-gentle-out: opacity $time-l ease-in-out;
	$transition-transform-gentle-in: transform $time-m ease;
	$transition-transform-gentle-out: transform $time-l ease-in-out;
	$transition-fill-in: fill $time-xs ease, stroke $time-xs ease;
	$transition-fill-out: fill $time-l ease-in-out, stroke $time-l ease-in-out;

	/* Selection */

	@mixin selection {
		color: $color-xiangyabai;
		background-color: $color-xihongshi;
		text-shadow: none;
	}
	::selection { @include selection; }
	::-moz-selection { @include selection; }

	@mixin unselectable {
		user-select: none;					// Chrome, Opera
		-webkit-user-select: none;	// Safari
		-khtml-user-select: none;		// Konqueror HTML
		-moz-user-select: none;			// Firefox
		-ms-user-select: none;			// Internet Explorer/Edge
	}

	/* Allowed Pixel Error */
	$error: $spacing-3;



/*!*--- Basic component ---**/

	/* MyLogo */
	$logo-size: $spacing-7;
	$logo-active-scaler: $scaling-s;
	$logo-hand-position-L-left: 44.2px;
	$logo-hand-position-L-top: 34.2px;
	$logo-hand-position-R-left: -4.2px;
	$logo-hand-position-R-top: 34.2px;
	$logo-active-hand-position-L-left: 36.5px;
	$logo-active-hand-position-L-top: 6.3px;
	$logo-active-hand-position-R-left: 3.5px;
	$logo-active-hand-position-R-top: 6.3px;
	$logo-stroke-color-light: $color-gray1-33;
	$logo-dough-color-light: $color-bg-light;
	$logo-bg-color-light: $color-gray4-CC;
	$logo-stroke-color-dark: $color-black;
	$logo-dough-color-dark: $color-gray4-CC;
	$logo-bg-color-dark: $color-gray2-66;
	$logo-active-dough-color: $color-xiangyabai;
	$logo-active-bg-color: $color-xihongshi;
	$logo-transition-in: transform ($transition-bouncer-s-time * $time-xs) $transition-bouncer-s;
	$logo-transition-out: transform $time-s ease;
	$logo-content-transition-in-time: $time-s ease;
	$logo-content-transition-out-time: $time-m ease-in-out;
	$logo-bg-transition-in: $transition-fill-in;
	$logo-bg-transition-out: fill $logo-content-transition-out-time;
	$logo-hand-transition-in: left $logo-content-transition-in-time, top $logo-content-transition-in-time;
	$logo-hand-transition-out: left $logo-content-transition-out-time, top $logo-content-transition-out-time;

	.logo-container {
		display: inline-block;
		position: relative;
		width: $logo-size;
		height: $logo-size;
		@include unselectable;
	}

	.logo {
		display: inline-block;
		// position: relative;
		width: $logo-size;
		height: $logo-size;
		// @include unselectable;
		transform: scale(1);
		@include transition($transition: $logo-transition-out);

		.logo-body {
			@include position-center-absolute;

			.logo-bg {
				@include transition($transition: $logo-bg-transition-out);
			}
		}

		.logo-hand-L {
			position: absolute;
			transform: translate(-50%,-50%);
			left: $logo-hand-position-L-left;
			top: $logo-hand-position-L-top;
			@include transition($transition: $logo-hand-transition-out);
			pointer-events: none;
		}
		.logo-hand-R {
			position: absolute;
			transform: translate(-50%,-50%);
			left: $logo-hand-position-R-left;
			top: $logo-hand-position-R-top;
			@include transition($transition: $logo-hand-transition-out);
			pointer-events: none;
		}
	}

	.mode-light .logo {
		.logo-body-stroke { fill: $logo-stroke-color-light; }
		.logo-body-dough { fill: $logo-dough-color-light; }
		.logo-bg { fill: $logo-bg-color-light; }
	}
	.mode-dark .logo {
		.logo-body-stroke { fill: $logo-stroke-color-dark; }
		.logo-body-dough { fill: $logo-dough-color-dark; }
		.logo-bg { fill: $logo-bg-color-dark; }
	}

	.logo.logo-active {
		transform: scale($logo-active-scaler);
		@include transition($transition: $logo-transition-in);

		.logo-body-dough { fill: $logo-active-dough-color; }
		.logo-bg { fill: $logo-active-bg-color; }

		.logo-hand-L {
			position: absolute;
			transform: translate(-50%,-50%);
			left: $logo-active-hand-position-L-left;
			top: $logo-active-hand-position-L-top;
			@include transition($transition: $logo-hand-transition-in);
		}
		.logo-hand-R {
			position: absolute;
			transform: translate(-50%,-50%);
			left: $logo-active-hand-position-R-left;
			top: $logo-active-hand-position-R-top;
			@include transition($transition: $logo-hand-transition-in);
		}

		.logo-bg {
			@include transition($transition: $logo-bg-transition-in);
		}
	}

	/* Text */
	$text-max-width: 800px;
	$text-color-display-light: $color-lanlv;
	$text-color-display-dark: $color-gray2-66;
	$text-color-h1-light: $color-lanlv;
	$text-color-h1-dark: $color-gray3-99;
	$text-color-h2-light: $color-gray1-33;
	$text-color-h2-dark: $color-gray4-CC;
	$text-color-body-light: $color-black;
	$text-color-body-dark: $color-gray3-99;
	$text-color-body-important-light: $color-black;
	$text-color-body-important-dark: $color-gray4-CC;
	$text-color-caption-light: $color-gray3-99;
	$text-color-caption-dark: $color-gray3-99;

	%text-display {
		font-size: $font-display;
		line-height: $lineheight-display;
	}
	.language-en %text-display {
		font-family: $font-serif-en;
	}
	.language-cn %text-display {
		font-family: $font-serif-cn;
	}
	.mode-light %text-display {
		color: $text-color-display-light;
	}
	.mode-dark %text-display {
		color: $text-color-display-dark;
	}

	%text-h1 {
		font-size: $font-h1;
		line-height: $lineheight-h1;
	}
	.language-en %text-h1 {
		font-family: $font-serif-en;
	}
	.language-cn %text-h1 {
		font-family: $font-serif-cn;
	}
	.mode-light %text-h1 {
		color: $text-color-h1-light;
	}
	.mode-dark %text-h1 {
		color: $text-color-h1-dark;
	}

	%text-h2 {
		font-family: $font-serif-en;
		font-size: $font-h2;
		line-height: $lineheight-h2;
	}
	.language-en %text-h2 {
		font-family: $font-serif-en;
	}
	.language-cn %text-h2 {
		font-family: $font-sans-serif-bold-cn;
	}
	.mode-light %text-h2 {
		color: $text-color-h2-light;
	}
	.mode-dark %text-h2 {
		color: $text-color-h2-dark;
	}

	%text-body {
		font-size: $font-default;
		line-height: $lineheight-default;
	}
	.language-en %text-body {
		font-family: $font-sans-serif-light-en;
	}
	.language-cn %text-body {
		font-family: $font-sans-serif-cn;
	}
	.mode-light %text-body {
		color: $text-color-body-light;
	}
	.mode-dark %text-body {
		color: $text-color-body-dark;
	}

	%text-body-important {
		font-size: $font-default;
		line-height: $lineheight-default;
	}
	.language-en %text-body-important {
		font-family: $font-sans-serif-light-en;
	}
	.language-cn %text-body-important {
		font-family: $font-sans-serif-cn;
	}
	.mode-light %text-body-important {
		color: $text-color-body-important-light;
	}
	.mode-dark %text-body-important {
		color: $text-color-body-important-dark;
	}

	%text-caption {
		font-size: $font-hint;
		line-height: $lineheight-hint;
	}
	.language-en %text-caption {
		font-family: $font-sans-serif-light-en;
	}
	.language-cn %text-caption {
		font-family: $font-sans-serif-cn;
	}
	.mode-light %text-caption {
		color: $text-color-caption-light;
	}
	.mode-dark %text-caption {
		color: $text-color-caption-dark;
	}

	@mixin textstroke($color: $color-white, $width: $border-width-m) {
		text-shadow:
			(-1 * $width)	(-1 * $width)	0	$color,
			0							(-1 * $width)	0	$color,
			($width)			(-1 * $width)	0	$color,
			($width)			0							0	$color,
			($width)			($width)			0	$color,
			0							($width)			0	$color,
			(-1 * $width)	($width)			0	$color,
			(-1 * $width)	0							0	$color;
	}

	body {
		margin: 0;
		@extend %text-body;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		overscroll-behavior-y: none;
	}

	p {
		@extend %text-body;
	}
	// span {
	// 	font-size: $font-default;
	// 	line-height: $lineheight-default;
	// }

	/* Text Body */
	$paragraph-expandable-peek-height: $spacing-10;
	$paragraph-expandable-peek-minheight: $spacing-9;
	$paragraph-expandable-fade-height: 320px;
	$paragraph-expandable-mask-topextra: 100vh;
	$paragraph-expandable-mask-height: calc(100% + $paragraph-expandable-fade-height + $paragraph-expandable-mask-topextra + $error);
	$paragraph-expandable-prompt-bottom: $spacing-4;
	$paragraph-expandable-transition-time: ($transition-bouncer-xl-time * $time-m) $transition-bouncer-xl;
	$paragraph-expandable-expand-transition:
		height $paragraph-expandable-transition-time,
		-webkit-mask-position $paragraph-expandable-transition-time,
		mask-position $paragraph-expandable-transition-time;

	.paragraph-expandable-container {
		position: relative;
		align-self: stretch;
		display: flex;
	}

	.paragraph-expandable {
		align-self: stretch;
		overflow: hidden;
		height: $paragraph-expandable-peek-height;
		min-height: $paragraph-expandable-peek-minheight;
		-webkit-mask-image: linear-gradient(to bottom, black calc(100% - $paragraph-expandable-fade-height), transparent 100%);
		mask-image: linear-gradient(to bottom, black calc(100% - $paragraph-expandable-fade-height), transparent 100%);
		-webkit-mask-size: 100% $paragraph-expandable-mask-height;	// leave a bit of room for error
		mask-size: 100% $paragraph-expandable-mask-height;
		-webkit-mask-position: 0 calc(100% - $paragraph-expandable-mask-height);
		mask-position: 0 calc(100% - $paragraph-expandable-mask-height);
		@include transition($transition: $paragraph-expandable-expand-transition);

		&.expanded {
			height: auto;
			-webkit-mask-position: 0 (-1 * $paragraph-expandable-mask-topextra);
			mask-position: 0 (-1 * $paragraph-expandable-mask-topextra);
		}
	}

	.paragraph-expandable-prompt {
		position: absolute;
		transform: translateX(-50%);
		left: 50%;
		bottom: $paragraph-expandable-prompt-bottom;
		@extend %text-body;	//@extend %text-body-important;
		@extend %link;
	}
	.language-en .paragraph-expandable-prompt {
		font-family: $font-sans-serif-bold-en !important;
	}
	.language-cn .paragraph-expandable-prompt {
		font-family: $font-sans-serif-bold-cn !important;
	}

	/* List */
	$list-indent: $spacing-4;
	$list-bullet-gap: 0;

	ol, ul {
		align-self: stretch;
		display: flex;
		flex-direction: column;
		padding-left: $list-indent;
	}

	ol {
		list-style-type: decimal;
	}

	ul {
		list-style-type: disc;
	}

	li {
		display: list-item;
		padding-inline-start: $list-bullet-gap;
	}

	/* Link */
	$link-color: $color-lvsongshi;
	$link-underscore-width: $border-width-s;
	$link-hover-underscore-width: $border-width-l;
	$link-hover-transition-in: $time-xs ease;
	$link-hover-transition-out: $time-m ease;

	%link {
		white-space: nowrap;
		@include transition($transition: (color $link-hover-transition-out));

		// &.wrappable {
		// 	white-space: wrap;
		// }

		&::before {
			content: "";
			background-color: $link-color;
			height: $link-underscore-width;
			border-radius: $radius-inf;
			display: block;
			position: absolute;
			width: 100%;
			bottom: 0;
			z-index: -1;
			@include transition($transition: (background-color $link-hover-transition-out, height $link-hover-transition-out));
		}

		&:hover {
			color: $link-color !important;
			@include transition($transition: (color $link-hover-transition-in));

			&::before {
				height: $link-hover-underscore-width;
				background-color: $link-color !important;
				@include transition($transition: (background-color $link-hover-transition-in, height $link-hover-transition-in));
			}
		}
	}

	.language-en %link:hover {
		font-family: $font-sans-serif-en;
	}
	.language-cn %link:hover {
		font-family: $font-sans-serif-cn;
	}

	.mode-light %link::before {
		background-color: $text-color-display-light;
	}
	.mode-dark %link::before {
		background-color: $text-color-display-dark;
	}

	a {
		text-decoration: none;
		color:inherit;
		display: inline-block;

		&:not(.ghost) {
			position: relative;
			@extend %link;
		}

		&.clicked {}
	}

	/* Emoji */
	$emoji-padding-X: 0;

	.emoji {
		font-family: $font-emoji;
	}

	span.emoji {
		padding: 0 $emoji-padding-X;
	}

	/* World */

	html {
		&:has(.mode-light) { background-color: $color-bg-light; }
		&:has(.mode-dark) { background-color: $color-bg-dark; }

		&:has(.modal-on) {
			overflow: hidden;
			overscroll-behavior-x: none;
		}
	}

	.groundfloor {
		&.mode-light { background-color: $color-bg-light; }
		&.mode-dark { background-color: $color-bg-dark; }

		&.modal-on { pointer-events: none; }
	}

	.mode-light %world { background-color: $color-bg-light; }
	.mode-dark %world { background-color: $color-bg-dark; }

	/* Cursor */
	$cursor-default: url("./assets/basic/cursors/cursor_default.svg") 40 40;
	$cursor-pointer: url("./assets/basic/cursors/cursor_pointer.svg") 40 40;
	$cursor-zoomin: url("./assets/basic/cursors/cursor_zoomin.svg") 40 40;
	$cursor-zoomout: url("./assets/basic/cursors/cursor_zoomout.svg") 40 40;
	$cursor-grab: url("./assets/basic/cursors/cursor_grab.svg") 40 40;
	$cursor-grabbing: url("./assets/basic/cursors/cursor_grabbing.svg") 40 40;
	$cursor-progress: url("./assets/basic/cursors/cursor_progress.svg") 40 40;
	$cursor-readmore: url("./assets/basic/cursors/cursor_readmore.svg") 52 40;

	body {
		&:has(.cursor-default) { cursor: $cursor-default, default; }
		&:has(.cursor-pointer) { cursor: $cursor-pointer, pointer; }
		&:has(.cursor-zoomin) { cursor: $cursor-zoomin, zoom-in; }
		&:has(.cursor-zoomout) { cursor: $cursor-zoomout, zoom-out; }
		&:has(.cursor-grab) { cursor: $cursor-grab, grab; }
		&:has(.cursor-grabbing) { cursor: $cursor-grabbing, grabbing; }
		&:has(.cursor-progress) { cursor: $cursor-progress, wait; }
		&:has(.cursor-readmore) { cursor: $cursor-readmore, pointer; }
		&:has(.cursor-none) { cursor: auto; }
	}

	.cursor-default a { cursor: $cursor-default, default; }
	.cursor-pointer a { cursor: $cursor-pointer, pointer; }
	.cursor-zoomin a { cursor: $cursor-zoomin, zoom-in; }
	.cursor-zoomout a { cursor: $cursor-zoomout, zoom-out; }
	.cursor-grab a { cursor: $cursor-grab, grab; }
	.cursor-grabbing a { cursor: $cursor-grabbing, grabbing; }
	.cursor-progress a { cursor: $cursor-progress, wait; }
	.cursor-readmore a { cursor: $cursor-readmore, pointer; }
	.cursor-none a { cursor: auto; }

	.cursor-space-out {
		z-index: $z-top;
		position: fixed;
		left: 50vw;
		top: 50vh;
		pointer-events: none;
	}

	.cursor-space-in {
		position: relative;
	}

	.cursor-position {
		position: absolute;
		left: calc(var(--cursorX) - 50vw);
		top: calc(var(--cursorY) - 50vh);
	}

	.cursor {
		position: relative;
	}

	.cursor-shape {
		@include position-center-absolute;

		.cursor-fill-0 { fill: $color-xiangyabai; }
		.cursor-fill-1 { fill: $color-xihongshi; }
	}

	/* Mode Changing Helper */	//TODO!!!

	// @mixin mode-changing-transition {
	// 	@include transition($transition: all $time-l ease !important);
	// }

	// body:has(.mode-changing) {
	// 	//@include mode-changing-transition;
	// 	*:not(a):not(span) { @include mode-changing-transition; }
	// }

	// .groundfloor { position: relative; }

	// .groundfloor.mode-changing::before {	//TODO
	// 	position: absolute;
	// 	content: "";
	// 	width: 200px;
	// 	height: 200px;
	// 	border-radius: $radius-circle;
	// 	background-color: #000000;
	// }

	/* Icon */
	@mixin icon-shape($icon: none) {
		-webkit-mask-image: $icon;
		mask-image: $icon;
	}

	/* Button */
	$button-size: $spacing-7;
	$button-gap: $spacing-3;
	$button-bgcolor-light: transparent;
	$button-bgcolor-dark: transparent;
	$button-border-width: $border-width-s;
	$button-border-color-light: transparent;
	$button-border-color-dark: transparent;
	$button-icon-size: $spacing-5;
	$button-icon-color-light: $color-gray1-33;
	$button-icon-color-dark: $color-gray4-CC;
	$button-icon-hover-color-light: $color-lvsongshi;
	$button-icon-hover-color-dark: $color-lvsongshi;
	$button-icon-hover-scaling: $scaling-xs;
	$button-icon-hover-transition-in: transform ($transition-bouncer-s-time * $time-xs) $transition-bouncer-s, $transition-fill-in;
	$button-icon-hover-transition-out: transform $time-s ease, $transition-fill-out;

	%button {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: $button-size;
		height: $button-size;
		border-radius: $radius-inf;
		outline: $button-border-width solid;
		@include unselectable;
	}
	.mode-light %button {
		background-color: $button-bgcolor-light;
		outline-color: $button-border-color-light;
	}
	.mode-dark %button {
		background-color: $button-bgcolor-dark;
		outline-color: $button-border-color-dark;
	}
	.missout-browser %button {
		outline: none;
	}
	.mode-light.missout-browser %button {
		border: $button-border-width solid $button-border-color-light;
	}
	.mode-dark.missout-browser %button {
		border: $button-border-width solid $button-border-color-dark;
	}

	%button > .button-icon {
		width: $button-icon-size;
		height: $button-icon-size;
		@include transition($transition: $button-icon-hover-transition-out);
	}
	.mode-light %button > .button-icon {
		fill: $button-icon-color-light;
	}
	.mode-dark %button > .button-icon {
		fill: $button-icon-color-dark;
	}

	%button:hover > .button-icon {
		transform: scale($button-icon-hover-scaling);
		@include transition($transition: $button-icon-hover-transition-in);
	}
	.mode-light %button:hover > .button-icon {
		fill: $button-icon-hover-color-light;
	}
	.mode-dark %button:hover > .button-icon {
		fill: $button-icon-hover-color-dark;
	}

	/* Img */
	$img-brightness-dim: 80%;//80%*original
	$img-grayscale-dim: 20%;//20%+original

	img {
		display: block;
	}

	.mode-light %img-filter {
		filter: brightness(100%) grayscale(0%);
	}
	.mode-dark %img-filter {
		filter: brightness($img-brightness-dim) grayscale($img-grayscale-dim); // dark mode dimmer image
	}

	.img {
		width: 100%;
		flex-shrink: 0;
	}

	.gif {}

	/* Modal */
	$modal-space-bgcolor: $color-bg-dark;
	$modal-space-opacity: 80%;
	$modal-space-backdrop: blur(4px);
	$modal-content-padding-X: $spacing-8;
	$modal-content-padding-Y: 0;
	$modal-btn-padding: 0;	// + 24px in icon img
	$modal-btn-color-rest: $color-gray3-99;
	$modal-btn-color-active: $color-white;
	$modal-btn-transition-in: fill $time-xs ease;
	$modal-btn-transition-out: fill $time-s ease;

	.modal-space {
		pointer-events: auto;
		z-index: $z-top;
		width: 100vw;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		background-color: rgba($modal-space-bgcolor, $modal-space-opacity);
		backdrop-filter: $modal-space-backdrop;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		visibility: hidden;

		&.on {
			visibility: visible;
		}
	}

	.modal {
		position: relative;
		align-self: stretch;
		flex: 1 0 0;
	}

	.modal-content-container-out {
		position: absolute;
		top: $modal-content-padding-Y;
		bottom: $modal-content-padding-Y;
		left: $modal-content-padding-X;
		right: $modal-content-padding-X;
	}

	.modal-content-container-in {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		//overflow: scroll;	// TODO
	}

	.modal-btn {
		position: absolute;
		height: 100%;
		top: 0; bottom: 0;
		@include unselectable;
		display: inline-flex;
		align-items: center;

		&.modal-btn-prev { left: $modal-btn-padding; }
		&.modal-btn-next { right: $modal-btn-padding; }

		.modal-btn-icon {
			fill: $modal-btn-color-rest;
			@include transition($transition: $modal-btn-transition-out);
		}

		&:hover .modal-btn-icon {
			fill: $modal-btn-color-active;
			@include transition($transition: $modal-btn-transition-in);
		}
	}

	/* Image */
	$image-reel: $spacing-4;
	$image-min-width: 320px;
	$image-max-width: 100vw;
	$image-caption-gap: $spacing-4;
	$image-zoomable-scaling: $scaling-l;
	$image-zoomable-transition-in: transform $time-s ease;
	$image-zoomable-transition-out: transform $time-m ease-in-out;

	%zoomable-image {
		overflow: hidden;

		.img {
			@include transition($transition: $image-zoomable-transition-out);

			&:hover {
				transform: scale($image-zoomable-scaling);
				@include transition($transition: $image-zoomable-transition-in);
			}
		}
	}

	.modal .img {
		max-width: 100vw;
		max-height: 100vh;
	}

	.image-container {
		align-self: stretch;
		width: 100%;
		margin: $image-reel auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0px;
		gap: $image-caption-gap;
	}

	.image {
		width: var(--image-width, 100%);
		height: var(--image-height, auto);
		min-width: $image-min-width;
		max-width: $image-max-width;

		&.zoomable {
			@extend %zoomable-image;
		}
	}

	.image .img {
		@extend %img-filter;
	}

	.image-caption {
		align-self: stretch;
		@extend %text-caption;
		text-align: center;
	}

	/* Image Gallery */
	$gallery-gap: $spacing-4;
	$gallery-padding-X: $spacing-4;
	$gallery-height: var(--gallery-height, auto);
	$gallery-width: var(--gallery-width, auto);
	$gallery-autoplay-time: var(--gallery-autoplay-time, $time-inf);

	@keyframes gallery-autoplay {
		0% { transform: translateX(0); }
		100% { transform: translateX(-50%); }
	}

	.gallery-container-out {
		width: 100%;
		margin-top: $image-reel;
		margin-bottom: $image-reel;

		.gallery-container-in {
			display: inline-block;
			width: 100%;
			margin: 0 $gallery-padding-X;
		}

		.gallery {
			display: inline-flex;
			flex-direction: row;
			align-items: flex-start;
			gap: $gallery-gap;
			flex-wrap: nowrap;
		}

		.gallery-image.zoomable {
			@extend %zoomable-image;
		}

		&.fixed-height:not(.fixed-width) {
			.gallery-image .img {
				height: $gallery-height;
				width: auto;
			}
		}

		&.fixed-width:not(.fixed-height) {
			.gallery-image .img {
				width: $gallery-width;
				height: auto;
			}
		}

		&.fixed-height.fixed-width {

			.gallery {
				// TODO: bottom fade???
			}

			.gallery-image .img {
				max-height: $gallery-height;
				width: $gallery-width;
				object-fit: cover;
				object-position: left top;
				overflow: hidden;
			}
		}

		&.wrap {
			.gallery {
				flex-wrap: wrap;
			}
		}

		&.autoplay {
			overflow: hidden;

			.gallery-container-in {
				margin: 0 !important;
			}

			.gallery {
				padding-right: $gallery-gap;
				animation: gallery-autoplay $gallery-autoplay-time linear infinite;

				&.wait {
					animation-play-state: paused;
				}
			}
		}

		&.static:not(.wrap) {
			overflow: scroll;
		}
	}

	.gallery-image .img {
		@extend %img-filter;
	}

	/* Scrollable */
	$scrollable-frame-height: var(--frame-height);
	$scrollable-padding-X: $spacing-5;
	$scrollable-frame-maxheight: min(
		calc($scrollable-frame-height * 1px),
		calc(100vh - 2 * $scrollable-padding-X));
	$scrollable-frame-width: var(--frame-width);
	$scrollable-padding-Y: $spacing-8;
	$scrollable-frame-minwidth: calc(0.5 * $scrollable-frame-width * 1px);
	$scrollable-frame-maxwidth: min(
		calc($scrollable-frame-maxheight / $scrollable-frame-height * $scrollable-frame-width),
		calc(100vw -  2 * $scrollable-padding-Y));
	$scrollable-screen-top: var(--screen-top, 0);
	$scrollable-screen-right: var(--screen-right, 0);
	$scrollable-screen-bottom: var(--screen-bottom, 0);
	$scrollable-screen-left: var(--screen-left, 0);
	$scrollable-frame-fill-0-light: $color-bg-light;
	$scrollable-frame-fill-1-light: $color-gray4-CC;
	$scrollable-frame-fill-0-dark: $color-bg-dark;
	$scrollable-frame-fill-1-dark: $color-gray2-66;
	$scrollable-hintblob-transition-in: opacity $time-l ease-in-out !important;
	$scrollable-hintblob-transition-out: opacity $time-xs ease !important;
	$scrollable-screen-radius-mobile: 16px;
	$scrollable-screen-radius-desktop: 0px;

	.scrollable-container-out {
		align-self: stretch;
		width: 100%;
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		min-width: $scrollable-frame-minwidth;
	}

	.scrollable-container-mid {
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	.scrollable-container-in {
		position: relative;
		display: inline-block;
		width: calc($scrollable-frame-width * 1px);
		min-width: $scrollable-frame-minwidth;
		max-width: $scrollable-frame-maxwidth;
		padding-top: calc(100% / $scrollable-frame-width * $scrollable-frame-height);
	}

	.scrollable-content {
		position: absolute;
		top: calc(100% / $scrollable-frame-height * $scrollable-screen-top);
		bottom: calc(100% / $scrollable-frame-height * $scrollable-screen-bottom);
		left: calc(100% / $scrollable-frame-width * $scrollable-screen-left);
		right: calc(100% / $scrollable-frame-width * $scrollable-screen-right);
		overflow-y: scroll;

		& > * {
			width: 100%;
		}
	}

	.scrollable-mobile-frame .scrollable-content {
		border-radius: $scrollable-screen-radius-mobile;
	}

	.scrollable-desktop-frame .scrollable-content {
		border-radius: $scrollable-screen-radius-desktop;
	}

	.scrollable-frame-container {
		position: absolute;
		top: 0; bottom: 0; left: 0; right: 0;
		pointer-events: none;
	}

	.scrollable-frame {
		position: relative;
		z-index: 1;
	}

	.mode-light .scrollable-frame {
		.scrollable-frame-fill-0 { fill: $scrollable-frame-fill-0-light; stroke: transparent; }
		.scrollable-frame-stroke-0 { stroke: $scrollable-frame-fill-0-light; fill: transparent; }
		.scrollable-frame-fill-1 { fill: $scrollable-frame-fill-1-light; stroke: transparent; }
		.scrollable-frame-stroke-1 { stroke: $scrollable-frame-fill-1-light; fill: transparent; }
	}
	.mode-dark .scrollable-frame {
		.scrollable-frame-fill-0 { fill: $scrollable-frame-fill-0-dark; stroke: transparent; }
		.scrollable-frame-stroke-0 { stroke: $scrollable-frame-fill-0-dark; fill: transparent; }
		.scrollable-frame-fill-1 { fill: $scrollable-frame-fill-1-dark; stroke: transparent; }
		.scrollable-frame-stroke-1 { stroke: $scrollable-frame-fill-1-dark; fill: transparent; }
	}

	.scrollable-hintblob {
		@include transition($transition: $scrollable-hintblob-transition-out);

		&.hintblob-shown {
			opacity: 1;
			@include transition($transition: $scrollable-hintblob-transition-in);
		}
	}

	/* Prototype */
	$prototype-minwidth: 400px;
	$prototype-maxheight: calc(100vh - $spacing-8);

	.prototype-container-out {
		align-self: stretch;
		width: 100%;
		min-width: $prototype-minwidth;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: $image-caption-gap;
	}

	.prototype-container-mid {
		width: var(--frame-width, 100%);
		max-width: $prototype-maxheight;
		min-width: $prototype-minwidth;
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		position: relative;
	}

	.prototype-container-in {
		width: 100%;
		padding-top: 100%;
		position: relative;
		overflow: hidden;
	}

	.prototype {
		position: absolute;
		width: var(--prototype-width, 100%);
		height: var(--prototype-height, 100%);
		top: var(--prototype-top, 0);
		left: var(--prototype-left, auto);
		right: var(--prototype-right, auto);
	}

	.prototype-container-out.ratio-1-1 {
		.prototype-container-mid { max-width: $prototype-maxheight; }
		.prototype-container-in { padding-top: 100%; }
	}
	.prototype-container-out.ratio-3-4 {
		.prototype-container-mid { max-width: calc($prototype-maxheight / 3 * 4); }
		.prototype-container-in { padding-top: 75%; }
	}
	.prototype-container-out.ratio-4-3 {
		.prototype-container-mid { max-width: calc($prototype-maxheight / 4 * 3); }
		.prototype-container-in { padding-top: 133.33%; }
	}
	.prototype-container-out.ratio-1-2 {
		.prototype-container-mid { max-width: calc($prototype-maxheight * 2); }
		.prototype-container-in { padding-top: 50%; }
	}
	.prototype-container-out.ratio-2-1 {
		.prototype-container-mid { max-width: calc($prototype-maxheight / 2); }
		.prototype-container-in { padding-top: 200%; }
	}

	/* Model */
	$modal-padding: $spacing-7;
	$model-min-width: 400px;
	$model-max-width: calc(100vw - 2 * $modal-padding);
	$model-min-height: 400px;
	$model-max-height: calc(100vh - 2 * $modal-padding);

	.model {
		width: var(--model-width, 100%);
		height: var(--model-height, auto);

		div {
			display: block;
			min-width: $model-min-width;
			max-width: $model-max-width;
			min-height: $model-min-height;
			max-height: $model-max-height;
		}
	}

	/* Hint Blob */
	$hintblob-transition-in: opacity $time-xs ease;
	$hintblob-transition-out: opacity $time-s ease;

	%hintblob {
		pointer-events: none;
		z-index: $z-top;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		@include transition($transition: $hintblob-transition-out);
		@include unselectable;

		&.hintblob-shown {
			opacity: 1;
			@include transition($transition: $hintblob-transition-in);
		}

		& .hintblob-fill-blob {
			fill: $color-xihongshi;
		}

		& .hintblob-fill-text {
			fill: $color-xiangyabai;
		}

		&.fade .hintblob-fill-blob {	// TODO: use this!?
			fill: $color-gray3-99;
		}
	}

	.hintblob-left-top {
		@extend %hintblob;
		left: var(--hintblob-left);
		top: var(--hintblob-top);
	}

	.hintblob-right-top {
		@extend %hintblob;
		left: auto;
		right: var(--hintblob-right);
		top: var(--hintblob-top);
	}

	/* Object */
	$object-space-width: 320px;
	$object-space-height: 1080px;
	$object-width: 200px;
	$object-height: 1000px;
	$object-hover-transition-in: $transition-transform-gentle-in;
	$object-hover-transition-in-bounce: transform ($transition-bouncer-l-time * $time-m) $transition-bouncer-l;
	$object-hover-transition-out: $transition-transform-gentle-out;
	$object-fill-1-light: $color-lanlv;
	$object-fill-2-light: $color-bg-light;
	$object-fill-1-dark: $color-lvsongshi;
	$object-fill-2-dark: $color-bg-dark;
	$object-noncurr-fill-1-light: $color-gray4-CC;
	$object-noncurr-fill-2-light: $color-gray4-CC;
	$object-noncurr-fill-1-dark: $color-gray2-66;
	$object-noncurr-fill-2-dark: $color-gray2-66;
	$animal-fill-1-light: $color-lvsongshi;
	$animal-fill-2-light: $color-bg-light;
	$animal-fill-1-dark: $color-gray1-33;
	$animal-fill-2-dark: $color-bg-dark;
	$object-fill-transition-in: $transition-fill-in;
	$object-fill-transition-out: $transition-fill-out;

	%object-container {
		pointer-events: auto;
		position: relative;
	}

	@mixin object-container($resizer: 1, $curr-displace: 0, $noncurr-displace: 0) {
		@extend %object-container;
		width: $resizer * $object-width;
		height: $resizer * $object-height;
		@include transition($transition: $object-hover-transition-out);

		.object {
			pointer-events: none;
			width: $resizer * $object-space-width;
			height: $resizer * $object-space-height;
			position: absolute;
			transform: translateX(-50%);
			left: 50%;
			bottom: 0;

			.object-fill-1 { @include transition($transition: $object-fill-transition-out); }
			.object-stroke-1 { @include transition($transition: $object-fill-transition-out); }
			.object-fill-2 { @include transition($transition: $object-fill-transition-out); }
			.object-stroke-2 { @include transition($transition: $object-fill-transition-out); }
		}

		&.curr {
			transform: translateY(-1 * $curr-displace);
			@include transition($transition: $object-hover-transition-in-bounce);

			.object-fill-1 { @include transition($transition: $object-fill-transition-in); }
			.object-stroke-1 { @include transition($transition: $object-fill-transition-in); }
			.object-fill-2 { @include transition($transition: $object-fill-transition-in); }
			.object-stroke-2 { @include transition($transition: $object-fill-transition-in); }
		}

		&.noncurr {
			transform: translateY($noncurr-displace);
			@include transition($transition: $object-hover-transition-in);

			.object-fill-1 { @include transition($transition: $object-fill-transition-in); }
			.object-stroke-1 { @include transition($transition: $object-fill-transition-in); }
			.object-fill-2 { @include transition($transition: $object-fill-transition-in); }
			.object-stroke-2 { @include transition($transition: $object-fill-transition-in); }
		}
	}

	.mode-light {
		.object .object-fill-1 { fill: $object-fill-1-light; stroke: transparent; }
		.object .object-stroke-1 { stroke: $object-fill-1-light; fill: transparent; }
		.object .object-fill-2 { fill: $object-fill-2-light; stroke: transparent; }
		.object .object-stroke-2 { stroke: $object-fill-2-light; fill: transparent; }
		.animal .animal-fill-1 { fill: $animal-fill-1-light; stroke: transparent; }
		.animal .animal-stroke-1 { stroke: $animal-fill-1-light; fill: transparent; }
		.animal .animal-fill-2 { fill: $animal-fill-2-light; stroke: transparent; }
		.animal .animal-stroke-2 { stroke: $animal-fill-2-light; fill: transparent; }

		%object-container.curr {}

		%object-container.noncurr {
		.object .object-fill-1 { fill: $object-noncurr-fill-1-light; stroke: transparent; }
		.object .object-stroke-1 { stroke: $object-noncurr-fill-1-light; fill: transparent; }
		.object .object-fill-2 { fill: $object-noncurr-fill-2-light; stroke: transparent; }
		.object .object-stroke-2 { stroke: $object-noncurr-fill-2-light; fill: transparent; }
		}
	}

	.mode-dark {
		.object .object-fill-1 { fill: $object-fill-1-dark; stroke: transparent; }
		.object .object-stroke-1 { stroke: $object-fill-1-dark; fill: transparent; }
		.object .object-fill-2 { fill: $object-fill-2-dark; stroke: transparent; }
		.object .object-stroke-2 { stroke: $object-fill-2-dark; fill: transparent; }
		.animal .animal-fill-1 { fill: $animal-fill-1-dark; stroke: transparent; }
		.animal .animal-stroke-1 { stroke: $animal-fill-1-dark; fill: transparent; }
		.animal .animal-fill-2 { fill: $animal-fill-2-dark; stroke: transparent; }
		.animal .animal-stroke-2 { stroke: $animal-fill-2-dark; fill: transparent; }

		%object-container.curr {}

		%object-container.noncurr {
		.object .object-fill-1 { fill: $object-noncurr-fill-1-dark; stroke: transparent; }
		.object .object-stroke-1 { stroke: $object-noncurr-fill-1-dark; fill: transparent; }
		.object .object-fill-2 { fill: $object-noncurr-fill-2-dark; stroke: transparent; }
		.object .object-stroke-2 { stroke: $object-noncurr-fill-2-dark; fill: transparent; }
		}
	}

	/* Control */
	$control-height: $spacing-8;
	$control-padding-X: $spacing-8;
	$control-padding-Y: $spacing-4;
	$control-padding: $control-padding-Y $control-padding-X;
	$control-gap-l: $spacing-6;
	$control-gap-s: $spacing-5;
	$control-bgcolor-light: $color-bg-light;
	$control-bgcolor-dark: $color-bg-dark;
	$control-element-height: $spacing-7;
	$control-element-border: $border-width-s solid;
	$control-element-border-color-light: $color-gray4-CC;
	$control-element-border-color-dark: $color-gray2-66;

	%control {
		position: sticky;
		z-index: $z-top;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: $control-padding;
		left: 0px;
		top: 0px;
		width: calc(100% - 2 * $control-padding-X);
		height: $control-height - 2 * $control-padding-Y;
	}
	.mode-light %control {
		background-color: $control-bgcolor-light;
	}
	.mode-dark %control {
		background-color: $control-bgcolor-dark;
	}

	%control .control-leftgroup {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: $control-gap-s;
	}

	%control .control-rightgroup {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		gap: $control-gap-l;
	}

	%control .control-rightgroup-subgroup {
		height: $control-element-height;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: $control-gap-s;

		&:not(:first-child) {
			padding-left: $control-gap-l;
			border-left: $control-element-border;
		}
	}
	.mode-light %control .control-rightgroup-subgroup:not(:first-child) {
		border-color: $control-element-border-color-light;
	}
	.mode-dark %control .control-rightgroup-subgroup:not(:first-child) {
		border-color: $control-element-border-color-dark;
	}

	/* Control Btn */
	$control-btn-size: $control-element-height;
	$control-btn-bgcolor-light: $control-bgcolor-light;
	$control-btn-bgcolor-dark: $control-bgcolor-dark;
	$control-btn-icons-color-light: $color-gray1-33;
	$control-btn-icons-color-dark: $color-gray4-CC;
	$control-btn-hover-scaling: $scaling-s;
	$control-btn-hover-transition-in: transform ($transition-bouncer-s-time * $time-xs) $transition-bouncer-s;
	$control-btn-hover-transition-out: transform $time-s ease;
	$control-btn-itching-displace: $spacing-3;
	$control-btn-itching-transition: $time-m ease;

	%control-btn {
		display: inline-block;
		width: $control-btn-size;
		height: $control-btn-size;
		border-radius: $radius-inf;
		outline: $control-element-border;
		overflow: hidden;
	}
	.mode-light %control-btn {
		outline-color: $control-element-border-color-light;
		background-color: $control-btn-bgcolor-light;
	}
	.mode-dark %control-btn {
		outline-color: $control-element-border-color-dark;
		background-color: $control-btn-bgcolor-dark;
	}
	.missout-browser %control-btn {
		outline: none;
	}
	.mode-light.missout-browser %control-btn {
		border: $control-element-border $control-element-border-color-light;
	}
	.mode-dark.missout-browser %control-btn {
		border: $control-element-border $control-element-border-color-dark;
	}

	.mode-light %control-btn .control-btn-icon {
		fill: $control-btn-icons-color-light;
	}
	.mode-dark %control-btn .control-btn-icon {
		fill: $control-btn-icons-color-dark;
	}

	%control .control-btn {
		@extend %control-btn;
		position: relative;
	}

	%control .control-btn .control-btn-icon {
		@include position-center-absolute;
	}

	%control .control-btn .control-btn-icon {
		@include transition($transition: $control-btn-hover-transition-out);
	}

	%control .control-btn.control-btn-hover-default:hover .control-btn-icon {
		transform: translate(-50%,-50%) scale($control-btn-hover-scaling);
		@include transition($transition: $control-btn-hover-transition-in);
	}

	%control .control-btn.control-btn-totop-itching .control-btn-icon {
		animation: control-btn-totop-itching $control-btn-itching-transition;
	}
	@keyframes control-btn-totop-itching {	// TODO: fix funny glitch on hover
		0% { top: 50%; }
		50% { top: -49.999%; }
		50.001% { top: 99.999%; }
		100% { top: 50%; }
	}

	/* Control Toggle */
	$control-toggle-gap-l: $control-gap-l;
	$control-toggle-gap-s: $control-gap-s;
	$control-toggler-node-size: $control-btn-size;
	$control-toggler-gap: $spacing-3;
	$control-toggler-fg-color-light: $color-lvsongshi;
	$control-toggler-fg-color-dark: $color-gray1-33;
	$control-toggler-bg-color-light: $control-btn-icons-color-light;
	$control-toggler-bg-color-dark: $control-btn-icons-color-dark;
	$control-toggler-curr-border-width: $border-width-m;
	$control-toggler-curr-border: $control-toggler-curr-border-width solid;
	$control-toggler-curr-color: $color-lvsongshi;
	$control-toggler-curr-bgopacity-light: 0;
	$control-toggler-curr-bgopacity-dark: 1;
	$control-toggler-curr-position-left: $control-toggler-node-size / 2;
	$control-toggler-curr-position-right: $control-toggler-node-size / 2 + $control-toggler-node-size + $control-toggler-gap;
	$control-toggler-transition: $time-s $transition-bouncer-gentle;
	$control-toggler-transition-mask: clip-path $control-toggler-transition;
	$control-toggler-transition-ring: left $control-toggler-transition;
	$control-toggle-secondary-color-dark: $color-gray5-E3;
	$control-toggle-secondary-curr-color-light: $color-black;
	$control-toggle-secondary-curr-color-dark: $color-lvsongshi;
	$control-toggle-secondary-curr-indicator-height: $border-width-l;
	$control-toggle-secondary-curr-indicator-width: 50%;
	$control-toggle-secondary-curr-indicator-minwidth: $spacing-4;
	$control-toggle-secondary-curr-indicator-maxwidth: $spacing-8;
	$control-toggle-secondary-curr-indicator-radius: $radius-inf;
	$control-toggle-secondary-curr-indicator-color: $color-lvsongshi;
	$control-toggle-secondary-curr-indicator-transition-in: clip-path $time-s ease-out, opacity $time-xs ease;
	$control-toggle-secondary-curr-indicator-transition-out: clip-path $time-m ease-in, opacity $time-xs ease ($time-m - $time-xs);

	@keyframes control-toggle-transition-itching-left-fg {
		0% { @include clip-path($path: circle(($control-toggler-node-size / 2) at $control-toggler-curr-position-left ($control-toggler-node-size / 2))); }
		50% { @include clip-path($path: circle(($control-toggler-node-size / 2) at ($control-toggler-curr-position-left + $control-btn-itching-displace) ($control-toggler-node-size / 2))); }
		100% { @include clip-path($path: circle(($control-toggler-node-size / 2) at $control-toggler-curr-position-left ($control-toggler-node-size / 2))); }
	}
	@keyframes control-toggle-transition-itching-right-fg {
		0% { @include clip-path($path: circle(($control-toggler-node-size / 2) at $control-toggler-curr-position-right ($control-toggler-node-size / 2))); }
		50% { @include clip-path($path: circle(($control-toggler-node-size / 2) at ($control-toggler-curr-position-right - $control-btn-itching-displace) ($control-toggler-node-size / 2))); }
		100% { @include clip-path($path: circle(($control-toggler-node-size / 2) at $control-toggler-curr-position-right ($control-toggler-node-size / 2))); }
	}
	@keyframes control-toggle-transition-itching-left-ring {
		0% { left: $control-toggler-curr-position-left; }
		50% { left: $control-toggler-curr-position-left + $control-btn-itching-displace; }
		100% { left: $control-toggler-curr-position-left; }
	}
	@keyframes control-toggle-transition-itching-right-ring {
		0% { left: $control-toggler-curr-position-right; }
		50% { left: $control-toggler-curr-position-right - $control-btn-itching-displace; }
		100% { left: $control-toggler-curr-position-right; }
	}

	@mixin control-toggle-secondary-tab-curr-indicator {
		content: "";
		height: $control-toggle-secondary-curr-indicator-height;
		width: $control-toggle-secondary-curr-indicator-width;
		min-width: $control-toggle-secondary-curr-indicator-minwidth;
		max-width: $control-toggle-secondary-curr-indicator-maxwidth;
		border-radius: $control-toggle-secondary-curr-indicator-radius;
		background-color: $control-toggle-secondary-curr-indicator-color;
		position: absolute;
		transform: translateX(-50%);
		left: 50%;
		bottom: 0;
	}

	@mixin control-toggle-secondary-tab-curr-indicator-shown {
		opacity: 1;
		@include clip-path($path: inset(0% 0% 0% 0% round $control-toggle-secondary-curr-indicator-radius));
		@include transition($transition: $control-toggle-secondary-curr-indicator-transition-in);
	}
	@mixin control-toggle-secondary-tab-curr-indicator-hidden {
		opacity: 0;
		@include clip-path($path: inset(0% 100% 0% 100% round $control-toggle-secondary-curr-indicator-radius));
		@include transition($transition: $control-toggle-secondary-curr-indicator-transition-out);
	}

	%control .control-toggle {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: $control-toggle-gap-l;
		@include unselectable;
	}

	%control .control-toggler {
		position: relative;
		width: 2 * $control-toggler-node-size + $control-toggler-gap;
		height: $control-toggler-node-size;
		border-radius: $radius-inf;
	}
	.mode-light %control .control-toggler {
		background-color: $control-btn-bgcolor-light;
	}
	.mode-dark %control .control-toggler {
		background-color: $control-btn-bgcolor-dark;
	}

	%control .control-toggler-fg {
		@include position-center-absolute;
		z-index: 1;
		@include clip-path($path: circle(($control-toggler-node-size / 2)));
		@include transition($transition: $control-toggler-transition-mask);
	}
	%control .control-toggler-left .control-toggler-fg {
		@include clip-path($path: circle(($control-toggler-node-size / 2) at $control-toggler-curr-position-left ($control-toggler-node-size / 2)));
	}
	%control .control-toggler-right .control-toggler-fg {
		@include clip-path($path: circle(($control-toggler-node-size / 2) at $control-toggler-curr-position-right ($control-toggler-node-size / 2)));
	}
	.mode-light %control .control-toggler-fg {
		fill: $control-toggler-fg-color-light;
	}
	.mode-dark %control .control-toggler-fg {
		fill: $control-toggler-fg-color-dark;
	}
	.missout-browser %control .control-toggler-left .control-toggler-fg {
		@include clip-path($path: circle(($control-toggler-node-size / 2) at left));
	}
	.missout-browser %control .control-toggler-right .control-toggler-fg {
		@include clip-path($path: circle(($control-toggler-node-size / 2) at right));
	}

	%control .control-toggler-bg {
		@include position-center-absolute;
	}
	.mode-light %control .control-toggler-bg {
		fill: $control-toggler-bg-color-light;
	}
	.mode-dark %control .control-toggler-bg {
		fill: $control-toggler-bg-color-dark;
	}

	%control .control-toggler::before {
		content: "";
		position: absolute;
		//@include position-center-absolute;
		width: 100%;
		height: 100%;
		border-radius: $radius-inf;
		outline: $control-element-border;
	}
	.mode-light %control .control-toggler::before {
		outline-color: $control-element-border-color-light;
	}
	.mode-dark %control .control-toggler::before {
		outline-color: $control-element-border-color-dark;
	}
	.missout-browser %control .control-toggler::before {
		outline: none;
	}
	.mode-light.missout-browser %control .control-toggler::before {
		border: $control-element-border $control-element-border-color-light;
	}
	.mode-dark.missout-browser %control .control-toggler::before {
		border: $control-element-border $control-element-border-color-dark;
	}

	%control .control-toggler::after {
		content: "";
		@include position-center-absolute;
		width: $control-toggler-node-size;
		height: $control-toggler-node-size;
		border-radius: $radius-circle;
		outline: $control-toggler-curr-border $control-toggler-curr-color;
		@include transition($transition: $control-toggler-transition-ring);
	}
	.missout-browser %control .control-toggler::after {
		outline: none;
		border: $control-toggler-curr-border $control-toggler-curr-color;
	}
	%control .control-toggler-left.control-toggler::after {
		left: $control-toggler-curr-position-left;
	}
	%control .control-toggler-right.control-toggler::after {
		left: $control-toggler-curr-position-right;
	}
	.mode-light %control .control-toggler::after {
		background-color: rgba($control-toggler-curr-color, $control-toggler-curr-bgopacity-light);
	}
	.mode-dark %control .control-toggler::after {
		width: $control-toggler-node-size + 2 * $control-toggler-curr-border-width;
		height: $control-toggler-node-size + 2 * $control-toggler-curr-border-width;
		outline: none;
		border: none;
		background-color: rgba($control-toggler-curr-color, $control-toggler-curr-bgopacity-dark);
	}

	%control .control-toggler.control-toggler-itching {
		&.control-toggler-left {
			.control-toggler-fg { animation: control-toggle-transition-itching-left-fg $control-btn-itching-transition; }
			&::after { animation: control-toggle-transition-itching-left-ring $control-btn-itching-transition; }
		}
		&.control-toggler-right {
			.control-toggler-fg { animation: control-toggle-transition-itching-right-fg $control-btn-itching-transition; }
			&::after { animation: control-toggle-transition-itching-right-ring $control-btn-itching-transition; }
		}
	}
	.missout-browser %control .control-toggler.control-toggler-itching {
		&.control-toggler-left {
			.control-toggler-fg { animation: none; }
			&::after { animation: none; }
		}
		&.control-toggler-right {
			.control-toggler-fg { animation: none; }
			&::after { animation: none; }
		}
	}

	%control .control-toggler-mask {
		clip-path: circle($control-toggler-node-size);
	}

	%control .control-toggle-secondary {
		height: $control-element-height;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0 $control-toggle-gap-l;
		gap: $control-toggle-gap-s;
		border-left: $control-element-border;
	}
	.mode-light .control-toggle-secondary {
		border-color: $control-element-border-color-light;
	}
	.mode-dark .control-toggle-secondary {
		border-color: $control-element-border-color-dark;
	}

	%control .control-toggle-secondary-tab {
		@extend %text-body-important;
		height: $control-element-height;
		position: relative;
		display: inline-flex;
		align-items: center;
		white-space: nowrap;

		&::before {
			@include control-toggle-secondary-tab-curr-indicator;
			@include control-toggle-secondary-tab-curr-indicator-hidden;
		}
	}
	.mode-dark .control-toggle-secondary-tab  {
		color: $control-toggle-secondary-color-dark;
	}

	.language-en %control .control-toggle-secondary-tab-curr {
		font-family: $font-sans-serif-bold-en;
	}
	.language-cn %control .control-toggle-secondary-tab-curr {
		font-family: $font-sans-serif-bold-cn;
	}
	.mode-light %control .control-toggle-secondary-tab-curr  {
		color: $control-toggle-secondary-curr-color-light;
	}
	.mode-dark %control .control-toggle-secondary-tab-curr  {
		color: $control-toggle-secondary-curr-color-dark;
	}

	%control .control-toggle-secondary-tab-curr::before {
		@include control-toggle-secondary-tab-curr-indicator-shown;
	}

	%control .control-toggle-secondary-tab:not(.control-toggle-secondary-tab-curr):hover::before {
		@include control-toggle-secondary-tab-curr-indicator-shown;
	}

	/* Control Switch */
	$control-switch-gap: $spacing-3;
	$control-switch-icons-position-left: $control-btn-size - $control-switch-gap / 2;
	$control-switch-icons-position-right: $control-switch-gap / 2;
	$control-switch-transition: left $time-s $transition-bouncer-gentle;

	@keyframes control-switch-transition-itching-left {
		0% { left: $control-switch-icons-position-left; }
		50% { left: $control-switch-icons-position-left - $control-btn-itching-displace; }
		100% { left: $control-switch-icons-position-left; }
	}
	@keyframes control-switch-transition-itching-right {
		0% { left: $control-switch-icons-position-right; }
		50% { left: $control-switch-icons-position-right + $control-btn-itching-displace; }
		100% { left: $control-switch-icons-position-right; }
	}

	%control .control-switch {
		@extend %control-btn;
		position: relative;
		@include unselectable;
	}

	%control .control-switch-icons {
		position: absolute;
		transform: translate(-50%,-50%);
		top: 50%;
		@include transition($transition: $control-switch-transition);
	}
	.mode-light %control .control-switch-icons {
		fill: $control-btn-icons-color-light;
	}
	.mode-dark %control .control-switch-icons {
		fill: $control-btn-icons-color-dark;
	}

	%control .control-switch-left .control-switch-icons {
		left: $control-switch-icons-position-left;
	}
	%control .control-switch-right .control-switch-icons {
		left: $control-switch-icons-position-right;
	}

	%control .control-switch.control-switch-itching {
		&.control-switch-left .control-switch-icons {
			animation: control-switch-transition-itching-left $control-btn-itching-transition;
		}
		&.control-switch-right .control-switch-icons {
			animation: control-switch-transition-itching-right $control-btn-itching-transition;
		}
	}

	/* Control Expandable */
	$control-expandable-expand-transition-in: $time-m ease;
	$control-expandable-expand-transition-out: $time-l ease-in-out;
	$control-expandable-duckling-num: var(--num-duckling, 1);

	%control .control-expandable-container {
		height: 100%;
		position: relative;
	}

	%control .control-expandable {
		@extend %control-btn;
		position: relative;
		@include transition($transition: width $control-expandable-expand-transition-out);

		.control-expandable-icons {
			position: absolute;
			transform: translateY(-50%);
			top: 50%;
			left: 0;
			display: flex;
			flex-direction: row;
			align-items: center;
			@include transition($transition: left $control-expandable-expand-transition-out);
		}

		.control-expandable-motherduck {
			width: $control-btn-size;
			height: $control-btn-size;
			border-radius: $radius-inf;
		}

		.control-btn {
			outline: none;
			border: none !important;
		}
	}

	%control .control-expandable.control-expandable-expanded {
		width: calc($control-expandable-duckling-num * $control-btn-size);
		@include transition($transition: width $control-expandable-expand-transition-in);

		.control-expandable-icons {
			left: -1 * $control-btn-size;
			@include transition($transition: left $control-expandable-expand-transition-in);
		}
	}

	/* 404 Page Not Found */
	$pnf-maxwidth: 4040px;
	$pnf-padding-X: $spacing-10;
	$pnf-padding: $spacing-8 $pnf-padding-X 0;
	$pnf-text-lineheight: 1.2 * $font-display;
	$pnf-text-color-light: $color-lanlv;
	$pnf-text-color-dark: $color-gray2-66;
	$pnf-hint-color: $color-xihongshi;
	$pnf-hint-dot-transition: opacity $time-l ease-in-out;

	.pnf {
		margin: 0 auto;
		width: calc(100vw - 2 * $pnf-padding-X);
		max-width: $pnf-maxwidth;
		height: 100vh;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		padding: $pnf-padding;
	}

	.pnf-text {
		@extend %text-display;
		line-height: $pnf-text-lineheight;
		align-self: stretch;
		display: flex;
		flex-direction: column;
	}
	.mode-light .pnf-text {
		color: $pnf-text-color-light;
	}
	.mode-dark .pnf-text {
		color: $pnf-text-color-dark;
	}

	.pnf-hint {
		color: $pnf-hint-color;

		.pnf-hint-dot {
			opacity: 0;
			@include transition($transition: $pnf-hint-dot-transition);

			&.shown {
				opacity: 1;
			}
		}
	}

	.pnf-img {
		width: 100%;
	}

	/* Others */
	iframe { border: none; }



/*!*--- Desktop ---**/
	@media (min-width: 800px) {

		//...
	}



/*!*--- Mobile ---**/
