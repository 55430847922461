	//@use "style";
	@import "_style.scss";

	/* Home */

	$home-padding-X: $spacing-8;
	$home-padding-Y: $spacing-7;
	$home-gap: $spacing-7;
	$home-object-active-fill-1-light: var(--theme-title-color-light, $object-fill-1-light);
	$home-object-active-fill-1-dark: var(--theme-title-color-dark, $object-fill-1-dark);
	$home-object-active-fill-2-light: $color-bg-light;
	$home-object-active-fill-2-dark: $color-bg-dark;
	$home-case-title-color-active-light: var(--theme-title-color-light, $color-gray1-33);
	$home-case-title-color-active-dark: var(--theme-title-color-dark, $color-gray4-CC);
	//$home-thanks-height: 280px;

	.home {
		@extend %world;
		display: flex;
		flex-direction: column;
		//padding: $home-padding-Y home-padding-X;
		//gap: $home-gap;
		// height: 100vh;
		// overflow-y: scroll;
		// scroll-snap-type: y mandatory;
	}

	// .home-cases-container {
	// 	align-self: stretch;
	// 	display: flex;
	// 	flex-direction: column;
	// 	scroll-snap-align: start;
	// }

	/* Section */

	$home-section-radius: $radius-l;
	$home-section-transition-in-delay: $time-m;
	$home-section-transition-in: $time-l ease $home-section-transition-in-delay;
	$home-section-bg-update-time: $time-sec;
	$home-section-bgcolor-light: $color-gray6-F0;
	$home-section-bgcolor-dark: $color-gray1-33;
	$home-section-bgcolor-active-light: var(--theme-bgcolor-light, $home-section-bgcolor-light);
	$home-section-bgcolor-active-dark: $home-section-bgcolor-dark;//var(--theme-bgcolor-dark, $home-section-bgcolor-dark)
	$home-section-bgcolor-transition-in: background-color $home-section-transition-in;
	$home-section-bgcolor-transition-out: $transition-bgcolor-out;
	$home-section-color-transition-in: color $home-section-transition-in;
	$home-section-color-transition-out: $transition-color-out;
	$home-section-rotate-X: 15deg;
	$home-section-rotate-Y: 10deg;
	$home-section-rotate-perspective: 500vw;
	$home-section-rotate-time: 8*$time-sec;
	$home-section-bg-prerotate-time: $time-sec;
	$home-section-bg-prerotate-transition: transform $home-section-bg-prerotate-time ease;

	%home-section {
		position: relative;
		display: flex;
	}

	@keyframes home-section-bg-updater {
		from { transform: rotateY(0deg); }
		to { transform: rotateY(0.001deg); }
	}
	@keyframes home-section-bg-rotate {
		0% { transform: perspective($home-section-rotate-perspective) rotateX($home-section-rotate-X) rotateY($home-section-rotate-Y); }
		25% { transform: perspective($home-section-rotate-perspective) rotateX(-1*$home-section-rotate-X) rotateY($home-section-rotate-Y); }
		50% { transform: perspective($home-section-rotate-perspective) rotateX(-1*$home-section-rotate-X) rotateY(-1*$home-section-rotate-Y); }
		75% { transform: perspective($home-section-rotate-perspective) rotateX($home-section-rotate-X) rotateY(-1*$home-section-rotate-Y); }
		100% { transform: perspective($home-section-rotate-perspective) rotateX($home-section-rotate-X) rotateY($home-section-rotate-Y); }
	}
	// @keyframes home-section-bg-rotate {
	// 	0% { clip-path: polygon(1% -1%, 104% -2%, 99% 101%, 5% 94%); }
	// 	25% { clip-path: polygon(-4% -2%, 99% -1%, 95% 94%, 1% 101%); }
	// 	50% { clip-path: polygon(1% -1%, 95% 6%, 99% 101%, -4% 102%); }
	// 	75% { clip-path: polygon(5% 6%, 99% -1%, 104% 102%, 1% 101%); }
	// 	100% { clip-path: polygon(1% -1%, 104% -2%, 99% 101%, 5% 94%); }
	// }

	%home-section {
		.home-section-bg-canvas {
			pointer-events: none;
			position: absolute;
			top: 0; bottom: 0; left: 0; right: 0;
		}

		.home-section-bg-clipper-container {
			width: 100%;
			height: 100%;
		}

		.home-section-bg-clipper {
			fill: none;
			pointer-events: none;
			width: calc(100% - 2 * $home-padding-X);
			height: calc(100% - 2 * $home-padding-Y);
			x: $home-padding-X;
			y: $home-padding-Y;
			rx: $home-section-radius;
			transform-origin: 50% 50%;
			transform: rotateX(0) rotateY(0);
			//@include transition($transition: $home-section-bg-prerotate-transition);
			animation: home-section-bg-updater $home-section-bg-update-time ease infinite 10ms;	// works like charm with a delay for some unknown reason
		}

		.home-section-bg-container {
			display: block;
			width: 100%;
			height: 100%;
		}

		.home-section-bg {
			content: "";
			position: absolute;
			top: 0; bottom: 0; left: 0; right: 0;
			z-index: $z-bg;
			@include transition($transition: $home-section-bgcolor-transition-out);
			animation: home-section-bg-updater $home-section-bg-update-time ease infinite;
		}

		&:not(.home-section-fancy) {

			.home-section-bg-container {
				position: relative;
				display: inline-block;
				border-radius: $home-section-radius;
				overflow: hidden;
			}
		}

		&.home-section-fancy {

			.home-section-bg-canvas {
				width: 100%;
				height: 100%;
			}
		}

		&.home-section-active:not(.home-section-substituted) .home-section-bg {
			@include transition($transition: $home-section-bgcolor-transition-in);
		}
	}

	.mode-light %home-section .home-section-bg {
			background-color: $home-section-bgcolor-light;
	}

	.mode-dark %home-section .home-section-bg {
			background-color: $home-section-bgcolor-dark;
	}

	%home-section .home-section-fg-container {
		align-self: stretch;
		flex-grow: 1;
		z-index: 1;
	}



	/* Hero */

	$home-hero-padding-X: $spacing-7;
	$home-hero-padding-Y: $spacing-7;
	$home-hero-gap-X: $spacing-7 + $spacing-3;
	$home-hero-gap-Y: $spacing-7;
	$home-hero-fg-displace: $spacing-8;
	$home-hero-fg-transition-in: transform $time-l ease, opacity $time-m ease $time-s;
	$home-hero-fg-transition-out: transform $time-l ease-in-out $time-xs, opacity $time-m ease $time-xs;

	.home-hero-container {
		overflow: hidden;
	}

	.home-hero {
		@extend %home-section;
		padding: $home-padding-Y 0 ($home-gap - $control-padding-Y);
		// scroll-snap-align: start;
		z-index: 1;
	}
	.mode-light .home-hero { background-color: $color-bg-light; }
	.mode-dark .home-hero { background-color: $color-bg-dark; }

	.home-hero .home-section-bg-clipper {
		width: calc(100% - 2 * ($home-padding-X - $home-hero-padding-X));
		x: $home-padding-X - $home-hero-padding-X;
		animation: home-section-bg-rotate $home-section-rotate-time cubic-bezier(.6,.2,.4,.8) infinite;
	}

	.home-hero .home-section-fg-container {
		pointer-events: none;
		position: relative;
	}

	.home-hero .home-hero-fg {
		position: relative;
		pointer-events: none;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		padding: $home-hero-padding-Y $home-padding-X;
		gap: $home-hero-gap-Y $home-hero-gap-X;
		flex-wrap: wrap;
		opacity: 0;
		@include transition($transition: $home-hero-fg-transition-out);

		&.home-hero-fg-hero {
			transform: translateY(-1 * $home-hero-fg-displace);
		}

		&.home-hero-fg-cases {
			position: absolute;
			//height: 100%;
			top: 0; /*bottom: 0;*/ left: 0; right: 0;
			//align-items: center;
			gap: $home-hero-gap-Y $home-hero-gap-X;
			transform: translateY($home-hero-fg-displace);
			@include unselectable;
		}

		&.curr {
			z-index: 1;
			transform: translateY(0);
			opacity: 1;
			@include transition($transition: $home-hero-fg-transition-in);
		}
	}

	/* Hero Self-intro */
	$home-hero-selfintro-gap: $spacing-5;
	$home-hero-selfintro-name-container-gap: $spacing-4;
	$home-hero-selfintro-name-gap: 0 $spacing-4;
	$home-hero-selfintro-name-cn-size: 112px;
	$home-hero-selfintro-name-cn-letterspacing: 3px;
	$home-hero-selfintro-name-cn-T: -4px;
	$home-hero-selfintro-name-color-light: $color-lanlv;
	$home-hero-selfintro-name-color-dark: $color-lvsongshi;
	$home-hero-selfintro-name-char-hover-color-light: $color-lvsongshi;
	$home-hero-selfintro-name-char-hover-color-dark: $color-gray4-CC;
	$home-hero-selfintro-name-char-hover-scaling: $scaling-m;
	$home-hero-selfintro-name-char-hover-transition-in: transform ($transition-bouncer-s-time * $time-s) $transition-bouncer-s, $transition-color-in;
	$home-hero-selfintro-name-char-hover-transition-out: $transition-transform-gentle-out, $transition-color-out;
	$home-hero-selfintro-title-color-light: $color-lvsongshi;
	$home-hero-selfintro-title-color-dark: $color-gray4-CC;

	.home-hero-selfintro-container {
		pointer-events: none;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.home-hero-selfintro {
		pointer-events: auto;
		align-self: stretch;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: $home-hero-selfintro-gap;
	}

	.home-hero-selfintro-name-container {
		align-self: stretch;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: $home-hero-selfintro-name-container-gap;
	}

	.home-hero-selfintro-name {
		transform: translateX(-4px);
		@extend %text-display;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: $home-hero-selfintro-name-gap;
		flex-wrap: wrap;
	}
	.mode-light .home-hero-selfintro-name {
		color: $home-hero-selfintro-name-color-light;
	}
	.mode-dark .home-hero-selfintro-name {
		color: $home-hero-selfintro-name-color-dark;
	}
	.language-cn .home-hero-selfintro-name {
		font-size: $home-hero-selfintro-name-cn-size;
	}

	.home-hero-selfintro-name-chargroup {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		flex-wrap: nowrap;
	}
	.language-cn .home-hero-selfintro-name-chargroup {
		transform: translateY($home-hero-selfintro-name-cn-T);
		gap: $home-hero-selfintro-name-cn-letterspacing;
	}

	.home-hero-selfintro-name-char {
		display: inline-block;
		z-index: 0;
		@include transition($transition: $home-hero-selfintro-name-char-hover-transition-out);
	}

	.home-hero-selfintro-name-char:hover {
		transform: scale($home-hero-selfintro-name-char-hover-scaling);
		z-index: 1;
		@include transition($transition: $home-hero-selfintro-name-char-hover-transition-in);
	}
	.mode-light .home-hero-selfintro-name-char:hover {
		color: $home-hero-selfintro-name-char-hover-color-light;
	}
	.mode-dark .home-hero-selfintro-name-char:hover {
		color: $home-hero-selfintro-name-char-hover-color-dark;
	}

	.home-hero-selfintro-title {
		@extend %text-h2;
	}
	.mode-light .home-hero-selfintro-title {
		color: $home-hero-selfintro-title-color-light;
	}
	.mode-dark .home-hero-selfintro-title {
		color: $home-hero-selfintro-title-color-dark;
	}

	.home-hero-selfintro-body {
		@extend %text-body-important;
	}

	/* Hero Contacts */
	$home-hero-contacts-container-gap: $spacing-5 $spacing-4;
	$home-hero-contacts-button-size: $button-size;
	$home-hero-contacts-button-icon-size: $button-icon-size;
	$home-hero-contacts-gap: $spacing-3;

	.home-hero-contacts-container {
		align-self: stretch;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		gap: $home-hero-contacts-container-gap;
		flex-wrap: wrap-reverse;
	}

	.home-hero-contacts {
		height: 0;
		margin:
			($home-hero-contacts-button-icon-size / 2)
			(-1 * ($home-hero-contacts-button-size - $home-hero-contacts-button-icon-size) / 2);
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: $home-hero-contacts-gap;
	}

	.home-hero-contacts-button-container {
		position: relative;
	}

	.home-hero-contacts-button {
		@extend %button;
	}

	/* Hero MyPic */
	$home-hero-mypic-container-size: 400px;
	$home-hero-mypic-container-radius: 24px;
	$home-hero-mypic-container-hover-scaling: $scaling-l;
	$home-hero-mypic-container-hover-transition-in: transform ($transition-bouncer-l-time * $time-m) $transition-bouncer-l;
	$home-hero-mypic-container-hover-transition-out: $transition-transform-gentle-out;
	$home-hero-mypic-hover-transition-in: opacity 200ms ease-in;
	$home-hero-mypic-hover-transition-out: opacity 400ms ease-in-out;

	.home-hero-mypic-container {
		pointer-events: auto;
		position: relative;
		overflow: hidden;
		width: $home-hero-mypic-container-size;
		height: $home-hero-mypic-container-size;
		border-radius: $home-hero-mypic-container-radius;
		@include transition($transition: $home-hero-mypic-container-hover-transition-out);
	}

	.home-hero-mypic {
		opacity: 1;
		position: absolute;
		width: 120% !important;
		transform: translate(-45%,-40%);
		left: 50%;
		top: 50%;
		min-width: 100%;
		min-height: min(100%, 800px, 50vw);
		object-fit: cover;
		@include transition($transition: $home-hero-mypic-hover-transition-out);
	}

	.home-hero-mypic-1 { z-index: 1; }
	.home-hero-mypic-2 { z-index: 0; }

	.home-hero-mypic-container:hover {
		transform: scale($home-hero-mypic-container-hover-scaling);
		@include transition($transition: $home-hero-mypic-container-hover-transition-in);
	}

	.home-hero-mypic-container:hover .home-hero-mypic-1 {
		opacity: 0;
		@include transition($transition: $home-hero-mypic-hover-transition-in);
	}

	/* Hero Object */
	$home-hero-objects-padding: $spacing-8;
	$home-hero-objects-gap: $spacing-7;
	$home-hero-object-resizer: 0.8;
	$home-hero-object-height: $spacing-9;
	$home-hero-object-displace-curr: $spacing-7;
	$home-hero-object-displace-noncurr: $spacing-7;
	$home-hero-objects-hintblob-transition-in: opacity $time-l ease, transform $time-l ease;
	$home-hero-objects-hintblob-transition-out: opacity $time-xs ease, transform $time-l ease;
	$home-hero-objects-hintblob-transition-out-displace: $spacing-4;

	.home-hero-objects-pillar {
		pointer-events: none;
		height: $home-hero-object-height;
	}

	.home-hero-objects {
		pointer-events: auto;
		position: absolute;
		right: $home-hero-objects-padding;
		bottom: -1 * ($home-hero-object-resizer * $object-height) + $home-hero-object-height + $home-padding-Y;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-end;
		gap: $home-hero-objects-gap;
	}

	.home-hero-object-container {
		@include object-container($resizer: $home-hero-object-resizer, $curr-displace: $home-hero-object-displace-curr, $noncurr-displace: $home-hero-object-displace-noncurr);
	}

	.mode-light .home-hero-object-container.curr .object {
		.object-fill-1 { fill: $home-object-active-fill-1-light; }
		.object-stroke-1 { stroke: $home-object-active-fill-1-light; }
		.object-fill-2 { fill: $home-object-active-fill-2-light; }
		.object-stroke-2 { stroke: $home-object-active-fill-2-light; }
	}
	.mode-dark .home-hero-object-container.curr .object {
		.object-fill-1 { fill: $home-object-active-fill-1-dark; }
		.object-stroke-1 { stroke: $home-object-active-fill-1-dark; }
		.object-fill-2 { fill: $home-object-active-fill-2-dark; }
		.object-stroke-2 { stroke: $home-object-active-fill-2-dark; }
	}

	.home-hero-objects-hintblob {
		@extend %hintblob;
		transform: translateY(-1*$home-hero-objects-hintblob-transition-out-displace);
		left: auto;
		right: 80px;
		top: -102px;
		@include transition($transition: $home-hero-objects-hintblob-transition-out);

		&.hintblob-shown {
			transform: translateY(0);
			@include transition($transition: $home-hero-objects-hintblob-transition-in);
		}
	}

	/* Hero Case */
	$home-hero-case-img-container-width: 400px;
	$home-hero-case-img-container-height: 400px;
	$home-hero-case-img-width: 480px;
	$home-hero-case-img-right: 40px;
	$home-hero-case-text-minwidth: 400px;
	$home-hero-case-text-gap-X: $spacing-8;
	$home-hero-case-text-gap-Y: $spacing-6;
	$home-hero-case-bio-gap-Y: $spacing-5;
	$home-hero-case-bio-entry-gap: $spacing-3;

	.mode-light .home-hero .home-section-bg {
		background-color: $home-section-bgcolor-active-light !important;
	}
	.mode-dark .home-hero .home-section-bg {
		background-color: $home-section-bgcolor-active-dark !important;
	}

	.home-hero-case-img-container {
		position: relative;
		width: $home-hero-case-img-container-width;
		height: $home-hero-case-img-container-height;
		flex-shrink: 0;
		@include unselectable;
	}

	.home-hero-case-img-container .img {
		width: $home-hero-case-img-width;
		position: absolute;
		transform: translateY(-50%);
		top: 50%;
		right: -1 * $home-hero-case-img-right;
	}
	.mode-dark .home-hero-case-img-container .img {
		filter: grayscale($img-grayscale-dim) brightness($img-brightness-dim);
	}

	$home-hero-case-text-container-width: calc(100vw - (2 * $home-padding-X) - (2 * $home-hero-padding-Y) - $home-hero-gap-X - $home-hero-case-img-width - $error);
	.home-hero-case-text-container {
		flex-grow: 1;
		width: $home-hero-case-text-container-width;
		min-width: max($home-hero-case-text-container-width, $home-hero-case-text-minwidth);	// these 3 lines together make the magic happen
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: $home-hero-case-text-gap-Y;
		@extend %text-body-important;
	}

	.home-hero-case-title {
		@extend %text-h1;
	}
	.mode-light .home-hero-case-title {
		color: $home-case-title-color-active-light !important;
	}
	.mode-dark .home-hero-case-title {
		color: $home-case-title-color-active-dark !important;
	}

	.home-hero-case-bio-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: $home-hero-case-bio-gap-Y;
	}

	.home-hero-case-bio {
		align-self: stretch;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: $home-hero-case-bio-gap-Y $home-hero-case-text-gap-X;
		flex-wrap: wrap;
	}

	.home-hero-case-bio-entry {
		display: inline-flex;
		flex-direction: row;
		align-items: flex-start;
		gap: $home-hero-case-bio-entry-gap;
	}

	.language-en .home-hero-case-bio-entry .home-hero-case-bio-entry-title {
		font-family: $font-sans-serif-bold-en;
	}
	.language-cn .home-hero-case-bio-entry .home-hero-case-bio-entry-title {
		font-family: $font-sans-serif-bold-cn;
	}



	/* Control */
	.home-control {
		@extend %control;
	}



	/* Cases */

	$home-cases-gap: $spacing-7;
	$home-cases-section-title-indent: $spacing-5;
	$home-cases-section-title-color-light: $color-lanlv;
	$home-cases-section-title-color-dark: $color-gray2-66;
	$home-cases-section-title-caption-color-light: $color-gray4-CC;
	$home-cases-section-title-caption-color-dark: $color-gray1-33;
	$home-cases-section-title-bullet-size: $spacing-10;
	$home-cases-section-title-bullet-img-size: 1.5 * $home-cases-section-title-bullet-size;
	$home-cases-section-title-bullet-color1-light: $color-lvsongshi;
	$home-cases-section-title-bullet-color2-light: $color-bg-light;
	$home-cases-section-title-bullet-color1-dark: $color-gray1-33;
	$home-cases-section-title-bullet-color2-dark: $color-bg-dark;
	$home-cases-section-title-bullet-peep-distance: $spacing-7;
	$home-cases-section-title-bullet-peep-transition-in: left ($transition-bouncer-l-time * $time-m) $transition-bouncer-l;
	$home-cases-section-title-bullet-peep-transition-out: left $time-l ease-in-out;

	.home-cases-container {
		position: relative;
	}

	.home-cases {
		position: relative;
		display: flex;
		flex-direction: column;
		//gap: $home-cases-gap;
		//margin-bottom: $home-thanks-height;
	}
	.mode-light .home-cases { background-color: $color-bg-light; }
	.mode-dark .home-cases { background-color: $color-bg-dark; }

	.home-cases-section {
		position: relative;
		display: flex;
		flex-direction: column;
		padding-bottom: $home-cases-gap;
	}

	.home-cases-section-title-container {
		position: relative;
		align-self: start;
		height: $home-cases-section-title-bullet-size;
		display: flex;
		padding: 0 $home-padding-X;
		align-items: center;
	}

	.home-cases-section-title {
		position: relative;
		left: $home-cases-section-title-indent;
		@extend %text-display;
		display: flex;
		flex-direction: column;
		@include transition($transition: $home-cases-section-title-bullet-peep-transition-out);
	}
	.mode-light .home-cases-section-title  {
		color: $home-cases-section-title-color-light;
	}
	.mode-dark .home-cases-section-title  {
		color: $home-cases-section-title-color-dark;
	}

	.home-cases-section-title-caption {
		@extend %text-h2;
	}
	.mode-light .home-cases-section-title-caption  {
		color: $home-cases-section-title-caption-color-light;
	}
	.mode-dark .home-cases-section-title-caption  {
		color: $home-cases-section-title-caption-color-dark;
	}

	.home-cases-section-title-bullet {
		position: absolute;
		transform: translate(-50%,-50%);
		top: 50%;
		left: 0;	// based on the format of bullet images
		width: $home-cases-section-title-bullet-img-size;
		height: $home-cases-section-title-bullet-img-size;
		pointer-events: none;
		@include transition($transition: $home-cases-section-title-bullet-peep-transition-out);
	}
	.mode-light .home-cases-section-title-bullet {
		.home-cases-section-title-bullet-color1 { fill: $home-cases-section-title-bullet-color1-light; }
		.home-cases-section-title-bullet-color2 { fill: $home-cases-section-title-bullet-color2-light; }
	}
	.mode-dark .home-cases-section-title-bullet {
		.home-cases-section-title-bullet-color1 { fill: $home-cases-section-title-bullet-color1-dark; }
		.home-cases-section-title-bullet-color2 { fill: $home-cases-section-title-bullet-color2-dark; }
	}

	.home-cases-section-title-bullet-placeholder {
		content: "";
		position: absolute;
		transform: translate(-50%,-50%);
		top: 50%;
		left: 0;
		width: $home-cases-section-title-bullet-size;
		height: $home-cases-section-title-bullet-size;
		border-radius: $radius-circle;
		pointer-events: none;
	}
	.mode-light .home-cases-section-title-bullet-placeholder {
		background-color: $home-cases-section-title-bullet-color1-light;
	}
	.mode-dark .home-cases-section-title-bullet-placeholder {
		background-color: $home-cases-section-title-bullet-color1-dark;
	}

	.home-cases-section-title-container:not(:has(.home-cases-section-title-bullet-placeholder)):hover {
		.home-cases-section-title {
			left: $home-cases-section-title-indent + $home-cases-section-title-bullet-peep-distance;
			@include transition($transition: $home-cases-section-title-bullet-peep-transition-in);
		}
		.home-cases-section-title-bullet {
			left: $home-cases-section-title-bullet-peep-distance;
			@include transition($transition: $home-cases-section-title-bullet-peep-transition-in);
		}
	}

	.home-cases-section-title-inview .home-cases-section-title-bullet {
		z-index: $z-top;
	}

	.home-cases-section-content {
		align-self: stretch;
		display: flex;
		flex-direction: column;
		padding: 0 $home-padding-X;
		gap: $home-cases-gap;
	}

	/* Case Card */
	//$home-case-height: 320px;
	$home-case-gap: 0;
	$home-case-img-width: 400px;
	$home-case-text-padding-X: $spacing-7;
	$home-text-margin-right: $spacing-10;
	$home-case-text-padding-T: $spacing-6;
	$home-case-text-padding-B: $spacing-7;
	$home-case-text-gap: $spacing-5;
	$home-case-bio-gap-X: $spacing-4;
	$home-case-bio-gap-Y: 0;
	$home-case-bio-height: $font-default;
	$home-case-bio-divider: $border-width-s solid;
	$home-case-bio-divider-color-light: $color-gray3-99;
	$home-case-bio-divider-color-dark: $color-gray3-99;
	$home-case-img-nonactive-grayscale-light: 1;
		$home-case-img-nonactive-brightness-light: 1.2;
		$home-case-img-nonactive-contrast-light: 1.2;
		$home-case-img-nonactive-opacity-light: 0.7;
	$home-case-img-nonactive-grayscale-dark: 1;
		$home-case-img-nonactive-brightness-dark: 0.35;
		$home-case-img-nonactive-contrast-dark: 0.8;
		$home-case-img-nonactive-opacity-dark: 1;
	$home-case-img-active-grayscale: 0;
		$home-case-img-active-brightness: 1;
		$home-case-img-active-contrast: 1;
		$home-case-img-active-opacity: 1;
	$home-case-img-active-transition-in-delay: $time-s;
	$home-case-img-active-transition-in-time: $time-l ease $home-case-img-active-transition-in-delay;
	$home-case-img-active-transition-out-time: $time-l ease;
	$home-case-img-active-transition-in:
		top $home-case-img-active-transition-in-time,
		transform $home-case-img-active-transition-in-time,
		filter $home-case-img-active-transition-in-time,
		opacity $home-case-img-active-transition-in-time;
	$home-case-img-active-transition-out:
		top $home-case-img-active-transition-out-time,
		transform $home-case-img-active-transition-out-time,
		filter $home-case-img-active-transition-out-time,
		opacity $home-case-img-active-transition-out-time;
	$home-case-object-resizer: 0.8;
	$home-case-object-size: $home-case-object-resizer * $object-width;
	$home-case-object-padding: $spacing-7;
	$home-case-object-ccwrotate: -45deg;
	$home-case-object-nonactive-fill-1-light: $color-gray3-99;
	$home-case-object-nonactive-fill-1-dark: $color-gray2-66;
	$home-case-object-nonactive-fill-2-light: $color-gray6-F0;
	$home-case-object-nonactive-fill-2-dark: $color-gray1-33;
	$home-object-active-fill-transition-in: fill $home-case-img-active-transition-in-time, stroke $home-case-img-active-transition-in-time;
	$home-case-object-hover-displace: $spacing-7;
	$home-case-object-hintblob-height: 85.2px;	// = 81px hintblob height + 4.2px gap
	$home-case-object-hintblob-margin-X: $spacing-4;
	$home-case-object-hintblob-margin-Y: $spacing-7;
	$home-case-objects-hintblob-hover-scale: 1.08;
	// $home-case-objects-hintblob-transition-in: opacity $home-case-img-active-transition-in-time, transform $time-s ease;
	// $home-case-objects-hintblob-hover-transition-in: opacity $time-xs ease, transform ($transition-bouncer-s-time * $time-xs) $transition-bouncer-s;
	// $home-case-objects-hintblob-transition-out: opacity $time-l ease, transform $time-s ease;
	$home-case-objects-hintblob-transition-in: opacity $home-case-img-active-transition-in-time;
	$home-case-objects-hintblob-hover-transition-in: opacity $time-xs ease;
	$home-case-objects-hintblob-transition-out: opacity $time-l ease;

	.home-case {
		@extend %home-section;
		z-index: 1;
		//height: $home-case-height;
	}

	.home-case.home-section-active:not(.home-section-substituted) {
		z-index: 2;
	}

	.home-case .home-section-bg-clipper {
		width: calc(100% - 2 * $home-padding-X);
		height: calc(100% - $home-cases-gap);
		x: $home-padding-X;
		y: $home-cases-gap / 2;
		rx: $home-section-radius;
		transform-origin: 50% 50%;
		transform: rotateX(0) rotateY(0);
		@include transition($transition: $home-section-bg-prerotate-transition);
	}

	.mode-light .home-case.home-section-active:not(.home-section-substituted) .home-section-bg {
		background-color: $home-section-bgcolor-active-light !important;
	}
	.mode-dark .home-case.home-section-active:not(.home-section-substituted) .home-section-bg {
		background-color: $home-section-bgcolor-active-dark !important;
	}

	.home-case .home-section-fg-container {
		pointer-events: none;
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		padding-right: $home-text-margin-right - $home-case-text-padding-X;
		gap: $home-case-gap;
		flex-wrap: wrap;
		height: 100%;
	}

	.home-case-img {
		align-self: stretch;
		flex-shrink: 0;
		width: $home-case-img-width;
		margin-right: -1 * $home-case-text-padding-X;
		position: relative;
		overflow: hidden;
		@include unselectable;

		.img {
			width: 100%;
			@include position-center-absolute;
			transform: translate(-50%,0);
			top: 0;
			@include transition($transition: $home-case-img-active-transition-out);
		}
	}

	.mode-light .home-case-img .img {
		filter: grayscale($home-case-img-nonactive-grayscale-light) brightness($home-case-img-nonactive-brightness-light) contrast($home-case-img-nonactive-contrast-light);
		opacity: $home-case-img-nonactive-opacity-light;
	}
	.mode-dark .home-case-img .img {
		filter: grayscale($home-case-img-nonactive-grayscale-dark) brightness($home-case-img-nonactive-brightness-dark) contrast($home-case-img-nonactive-contrast-dark);
		opacity: $home-case-img-nonactive-opacity-dark;
	}

	@keyframes home-case-img-active {
		0% { overflow: hidden; }
		99.999% { overflow: visible; }
		100% { overflow: visible; }
	}
	.home-section-active:not(.home-section-substituted) .home-case-img {
		animation: home-case-img-active $home-case-img-active-transition-in-time;
		animation-fill-mode: both;

		.img {
			transform: translate(-50%,-50%);
			top: 50%;
			filter: grayscale($home-case-img-active-grayscale) brightness($home-case-img-active-brightness) contrast($home-case-img-active-contrast);
			opacity: $home-case-img-active-opacity;
			@include transition($transition: $home-case-img-active-transition-in);
		}
	}
	.mode-dark .home-section-active:not(.home-section-substituted) .home-case-img .img {
		filter: grayscale($img-grayscale-dim + $home-case-img-active-grayscale) brightness($img-brightness-dim * $home-case-img-active-brightness) contrast($home-case-img-active-contrast);
	}

	$home-case-text-container-width: calc(100vw - (2 * $home-padding-X + $home-text-margin-right) - (2 * $home-case-text-padding-X) - $home-case-img-width - $error);
	.home-case-text-container {
		flex-grow: 1;
		width: $home-case-text-container-width;
		min-width: max($home-case-text-container-width, $home-case-img-width);	// these 3 lines together make the magic happen
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: $home-case-text-padding-T $home-case-text-padding-X $home-case-text-padding-B;
		gap: $home-case-text-gap;
		z-index: 1;
	}

	.home-case-title {
		@extend %text-h2;
		@include transition($transition: $home-section-color-transition-out);
	}

	.home-section-active:not(.home-section-substituted) .home-case-title {
		@include transition($transition: $home-section-color-transition-in);
	}

	.mode-light .home-section-active:not(.home-section-substituted) .home-case-title {
		color: $home-case-title-color-active-light !important;
	}
	.mode-dark .home-section-active:not(.home-section-substituted) .home-case-title {
		color: $home-case-title-color-active-dark !important;
	}

	.home-case-bio {
		align-self: stretch;
		@extend %text-body-important;
		line-height: $home-case-bio-height;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: $home-case-bio-gap-Y $home-case-bio-gap-X;
		flex-wrap: wrap;
	}

	.home-case-bio-item {
		display: inline-flex;
		flex-direction: row;
		align-items: center;

		&:not(:first-child) {
			padding-left: $home-case-bio-gap-X;
			border-left: $home-case-bio-divider;
		}
	}
	.mode-light .home-case-bio-item:not(:first-child) {
		border-color: $home-case-bio-divider-color-light;
	}
	.mode-dark .home-case-bio-item:not(:first-child) {
		border-color: $home-case-bio-divider-color-dark;
	}

	.home-case-brief {
		@extend %text-body;
		pointer-events: auto;
	}

	.home-case-object-container-out {
		position: absolute !important;
		width: $home-case-object-size;
		height: $home-case-object-size;
		bottom: -1 * $home-case-object-padding;
		right: -1 * $home-case-object-padding;
		z-index: 2;
		pointer-events: auto;
	}

	.home-section-active:not(.home-section-substituted) .home-case-object-container-out {
		height: $home-case-object-size + $home-case-object-hintblob-height;
	}

	.home-case-object-positioner {
		position: relative;
		display: inline-block;
		// width: 100%;
		// height: 100%;
	}
	.home-section-active:not(.home-section-substituted) .home-case-object-positioner {
		transform: translateY($home-case-object-hintblob-height);
	}

	.home-case-object-rotater {
		position: relative;
		display: inline-block;
		width: $home-case-object-size;
		height: $home-case-object-size;
		transform: rotate($home-case-object-ccwrotate);
	}

	.home-case-object-container-in {
		@include object-container($resizer: $home-case-object-resizer);
		display: inline-block;
	}

	.home-case:hover .home-case-object-container-in {
		transform: translateY(-1 * $home-case-object-hover-displace);
		@include transition($transition: $object-hover-transition-in-bounce);
	}

	.mode-light .home-case-object-container-in .object {
		.object-fill-1 { fill: $home-case-object-nonactive-fill-1-light; }
		.object-stroke-1 { stroke: $home-case-object-nonactive-fill-1-light; }
		.object-fill-2 { fill: $home-case-object-nonactive-fill-2-light; }
		.object-stroke-2 { stroke: $home-case-object-nonactive-fill-2-light; }
	}
	.mode-dark .home-case-object-container-in .object {
		.object-fill-1 { fill: $home-case-object-nonactive-fill-1-dark; }
		.object-stroke-1 { stroke: $home-case-object-nonactive-fill-1-dark; }
		.object-fill-2 { fill: $home-case-object-nonactive-fill-2-dark; }
		.object-stroke-2 { stroke: $home-case-object-nonactive-fill-2-dark; }
	}

	.home-section-active:not(.home-section-substituted) .object {
		.object-fill-1 { @include transition($transition: $home-object-active-fill-transition-in); }
		.object-stroke-1 { @include transition($transition: $home-object-active-fill-transition-in); }
		.object-fill-2 { @include transition($transition: $home-object-active-fill-transition-in); }
		.object-stroke-2 { @include transition($transition: $home-object-active-fill-transition-in); }
	}
	.mode-light .home-section-active:not(.home-section-substituted) .home-case-object-container-in .object {
		.object-fill-1 { fill: $home-object-active-fill-1-light; }
		.object-stroke-1 { stroke: $home-object-active-fill-1-light; }
		.object-fill-2 { fill: $home-object-active-fill-2-light; }
		.object-stroke-2 { stroke: $home-object-active-fill-2-light; }
	}
	.mode-dark .home-section-active:not(.home-section-substituted) .home-case-object-container-in .object {
		.object-fill-1 { fill: $home-object-active-fill-1-dark; }
		.object-stroke-1 { stroke: $home-object-active-fill-1-dark; }
		.object-fill-2 { fill: $home-object-active-fill-2-dark; }
		.object-stroke-2 { stroke: $home-object-active-fill-2-dark; }
	}

	.home-case:hover .home-case-object-container-in .object {
		.object-fill-1 { @include transition($transition: $object-fill-transition-in); }
		.object-stroke-1 { @include transition($transition: $object-fill-transition-in); }
		.object-fill-2 { @include transition($transition: $object-fill-transition-in); }
		.object-stroke-2 { @include transition($transition: $object-fill-transition-in); }
	}
	.mode-light .home-case:hover .home-case-object-container-in .object {
		.object-fill-1 { fill: $home-object-active-fill-1-light; }
		.object-stroke-1 { stroke: $home-object-active-fill-1-light; }
		.object-fill-2 { fill: $home-object-active-fill-2-light; }
		.object-stroke-2 { stroke: $home-object-active-fill-2-light; }
	}
	.mode-dark .home-case:hover .home-case-object-container-in .object {
		.object-fill-1 { fill: $home-object-active-fill-1-dark; }
		.object-stroke-1 { stroke: $home-object-active-fill-1-dark; }
		.object-fill-2 { fill: $home-object-active-fill-2-dark; }
		.object-stroke-2 { stroke: $home-object-active-fill-2-dark; }
	}

	.home-case-object-hintblob {
		@extend %hintblob;
		//transform: translateY(-100%) scale(1);
		transform: translateY(-100%);
		left: auto;
		right: calc($home-case-object-padding + var(--hintblob-right));
		top: var(--hintblob-top);
		@include transition($transition: $home-case-objects-hintblob-transition-out);

		&.hintblob-shown {
			//top: -1 * $home-case-object-hintblob-margin-X;
			@include transition($transition: $home-case-objects-hintblob-transition-in);
		}
	}

	.home-case:hover .home-case-object-hintblob {
		//transform: translateY(-100%) scale($home-case-objects-hintblob-hover-scale);
		@include transition($transition: $home-case-objects-hintblob-hover-transition-in);
	}



	/* Thanks */

	$home-thanks-padding-Y: $spacing-8;
	$home-thanks-gap: $spacing-5;
	$home-thanks-color: $color-lvsongshi !important;

	.home-thanks-container {
		//position: fixed;
		//bottom: 0;
		//height: $home-thanks-height;
		display: flex;
		flex-direction: column;
		padding: $home-thanks-padding-Y $home-padding-X;
		margin-bottom: $home-padding-Y;
		gap: $home-thanks-gap;
	}

	.home-thanks {
		@extend %text-h2;	// @extend %text-display;
		color: $home-thanks-color;
	}

	.home-thanks-msg {
		@extend %text-body-important;
		max-width: $text-max-width;
		z-index: 1;
	}
