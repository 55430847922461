	//@use "style";
	@import "_style.scss";

	// Case
	$case-padding: $spacing-8;
	$case-gap: $spacing-8;
	$case-bgcolor-light: var(--theme-bgcolor-light, $color-gray6-F0);
	$case-bgcolor-dark: $color-gray1-33;//var(--theme-bgcolor-dark, $color-gray1-33)
	$case-display-color-light: var(--theme-title-color-light, $color-gray1-33);
	$case-display-color-dark: var(--theme-title-color-dark, $color-gray4-CC);
	$case-h1-color-light: $color-gray3-99;
	$case-h1-color-dark: $color-gray3-99;
	$case-object-fill-1-light: var(--theme-title-color-light, $object-fill-1-light);
	$case-object-fill-1-dark: var(--theme-title-color-dark, $object-fill-1-dark);
	$case-object-fill-2-light: $color-bg-light;
	$case-object-fill-2-dark: $color-bg-dark;

	// Header
	$case-header-gap-X: $spacing-8;
	$case-header-gap-Y: $spacing-7;
	$case-header-bio-width: 240px;
	$case-header-bio-tldr-width: 400px;
	$case-header-bio-entry-gap: $spacing-2;
	$case-header-img-width: 800px;
	$case-header-img-maxwidth: 50vw;
	$case-header-img-maxheight: 100vh;
	$case-header-object-resizer: 0.8;
	$case-header-object-size: $case-header-object-resizer * $object-width;
	$case-header-object-hover-displace: $spacing-3;
	$case-header-object-hover-transition-in: transform ($transition-bouncer-l-time * $time-s) $transition-bouncer-l;
	$case-header-object-hover-transition-out: transform $time-m ease-in-out;
	$case-header-object-prompt-gap: $spacing-3;
	$case-header-object-prompt-fontsize: $font-h1;
	$case-header-object-prompt-lineheight-en: 0.84 * $case-header-object-prompt-fontsize;
	$case-header-object-prompt-lineheight-cn: 1.2 * $case-header-object-prompt-fontsize;
	$case-header-object-prompt-icon-size: 48px;
	$case-header-object-prompt-icon-hover-displace: $spacing-2;
	$case-header-object-prompt-icon-hover-transition-in: transform ($transition-bouncer-s-time * $time-xs) $transition-bouncer-s;
	$case-header-object-prompt-icon-hover-transition-out: transform $time-m ease-in-out;
	$case-header-object-prompt-color: $color-xihongshi;

	// Body
	$case-body-maxwidth: $text-max-width;
	$case-body-gap: $spacing-8;
	$case-body-section-gap: $spacing-7;
	$case-body-section-paragraph-gap: $spacing-5;
	$case-body-subsection-gap: $case-body-section-paragraph-gap;



	/* Case */

	%case {
		display: flex;
		flex-direction: column;
		padding-top: $case-padding;
		overflow: hidden;
	}

	.case-control {
		@extend %control;
		position: fixed;
		background-color: transparent !important;
		pointer-events: none;

		div {
			pointer-events: auto;
		}
	}

	/* Header */

	%case .case-header {
		align-self: stretch;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: $case-gap;
		padding: 0 $case-padding;
		z-index: 1;
	}

	%case .case-header-title {
		@extend %text-display;
	}
	.mode-light %case .case-header-title {
		color: $case-display-color-light;
	}
	.mode-dark %case .case-header-title {
		color: $case-display-color-dark;
	}

	%case .case-header-bio-container {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: $case-header-gap-X;
	}

	%case .case-header-bio {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: $case-header-gap-Y;
		width: $case-header-bio-width;
	}

	%case .case-header-bio-entry-container {
		align-self: stretch;
		@extend %text-body-important;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: $case-header-bio-entry-gap;

		&.case-header-bio-tldr {
			width: $case-header-bio-tldr-width;
			align-self: auto;

			.case-header-bio-entry-content {
				position: relative;
			}
		}

		.case-header-bio-entry-content {
			white-space: pre-wrap;
		}
	}

	.language-en %case .case-header-bio-entry-container .case-header-bio-entry-title {
		font-family: $font-sans-serif-bold-en;
	}
	.language-cn %case .case-header-bio-entry-container .case-header-bio-entry-title {
		font-family: $font-sans-serif-bold-cn;
	}

	@mixin pillarHeightCalc($object-bottom: 0px) {
		height: -1 * $object-bottom + $case-header-object-size / 2 - $case-header-bio-entry-gap;
	}

	%case .case-header-img {
		z-index: -1;	//z-index: $z-bg;
		width: $case-header-img-width;
		max-width: $case-header-img-maxwidth;
		max-height: $case-header-img-maxheight;
		@include position-center-absolute;
		@extend %img-filter;
		@include unselectable;
		//@include transition($transition: $home-case-img-active-transition-out);
	}

	%case .case-header-object-container-out {
		position: absolute;
		width: $case-header-object-size;
		height: $case-header-object-size;
	}

	%case .case-header-object-rotater {
		position: relative;
		display: inline-block;
		width: $case-header-object-size;
		height: $case-header-object-size;
		transform: rotate(0);
	}

	%case .case-header-object-container-in {
		@include object-container($resizer: $case-header-object-resizer, $curr-displace: $case-header-object-hover-displace);
		@include transition($transition: $case-header-object-hover-transition-out);

		&.curr {
			@include transition($transition: $case-header-object-hover-transition-in);
		}
	}

	.mode-light %case .case-header-object-container-in .object {
		.object-fill-1 { fill: $case-object-fill-1-light; }
		.object-stroke-1 { stroke: $case-object-fill-1-light; }
		.object-fill-2 { fill: $case-object-fill-2-light; }
		.object-stroke-2 { stroke: $case-object-fill-2-light; }
	}
	.mode-dark %case .case-header-object-container-in .object {
		.object-fill-1 { fill: $case-object-fill-1-dark; }
		.object-stroke-1 { stroke: $case-object-fill-1-dark; }
		.object-fill-2 { fill: $case-object-fill-2-dark; }
		.object-stroke-2 { stroke: $case-object-fill-2-dark; }
	}

	%case case-header-object-prompt-container {
		position: relative;
	}

	%case .case-header-object-prompt {
		position: absolute;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-end;
		gap: $case-header-object-prompt-gap;
		@include unselectable;
	}

	%case .case-header-object-prompt-text {
		text-align: right;
		white-space: pre-wrap;
		@extend %text-h1;
		color: $case-header-object-prompt-color !important;
		flex-shrink: 0;
	}
	.language-en %case .case-header-object-prompt-text {
		line-height: $case-header-object-prompt-lineheight-en;
	}
	.language-cn %case .case-header-object-prompt-text {
		line-height: $case-header-object-prompt-lineheight-cn;
	}
	.mode-light %case .case-header-object-prompt-text {
		@include textstroke($color: $color-bg-light);
	}
	.mode-dark %case .case-header-object-prompt-text {
		@include textstroke($color: $color-bg-dark);
	}

	%case .case-header-object-prompt-icon {
		fill: $case-header-object-prompt-color;
		width: $case-header-object-prompt-icon-size;
		height: $case-header-object-prompt-icon-size;
		overflow: visible;
		flex-shrink: 0;

		& #open-external-arrow {
			@include transition($transition: $case-header-object-prompt-icon-hover-transition-out);
		}
	}

	%case .case-header-object-prompt.focused .case-header-object-prompt-icon #open-external-arrow {
		transform: translate($case-header-object-prompt-icon-hover-displace, -1 * $case-header-object-prompt-icon-hover-displace);
		@include transition($transition: $case-header-object-prompt-icon-hover-transition-in);
	}

	%case .case-body {
		align-self: stretch;
		max-width: 100%;
		margin: $case-gap auto 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: $case-body-gap;
	}

	%case .case-body-section {
		align-self: stretch;
		max-width: $case-body-maxwidth;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0 $case-padding;
		gap: $case-body-section-gap;
	}

	%case .case-body-section-title {
		align-self: stretch;
		@extend %text-display;
	}
	.mode-light %case .case-body-section-title {
		color: $case-display-color-light;
	}

	.mode-dark %case .case-body-section-title-challenge-solution {
		color: $case-display-color-dark;
	}

	%case .case-body-gallery-collection {
		align-self: stretch;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: $case-body-section-gap;

		.case-body-section-title {
			margin: 0 $case-padding;
		}

		.gallery-container-in {
			margin: 0 $case-padding;
		}
	}

	%case .case-body-gallery-spotlight-container-out {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;

		.case-body-gallery-spotlight-container-in {
			@include position-center-absolute;
		}
	}

	%case .case-body-section-content {
		align-self: stretch;
		@extend %text-body;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: $case-body-section-paragraph-gap;

		.paragraph-expandable-content {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: $case-body-section-paragraph-gap;
		}
	}

	%case .case-body-subsection {
		align-self: stretch;
		max-width: $case-body-maxwidth;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: $case-body-subsection-gap;
	}

	%case .case-body-subsection-title {
		align-self: stretch;
		@extend %text-h1;
	}
	.mode-light %case .case-body-subsection-title {
		color: $case-h1-color-light;
	}
	.mode-dark %case .case-body-subsection-title {
		color: $case-h1-color-dark;
	}

	%case .case-body-subsection-content {
		align-self: stretch;
		@extend %text-body;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: $case-body-section-paragraph-gap;
	}

	/* Footer */
	$case-footer-height: 320px;
	$case-footer-object-container-size: 200px;
	$case-footer-object-resizer: 0.8;
	$case-footer-object-size: $case-footer-object-resizer * $object-width;
	$case-footer-object-hover-displace: $spacing-7;
	$case-footer-object-ccwrotate: -45deg;
	$case-footer-object-hover-transition-out: transform $time-m ease-in-out;

	%case .case-footer-container {
		position: relative;
		width: 100%;
		height: $case-footer-height;
	}

	%case .case-footer-empty {
		width: 100%;
		height: $case-padding;
	}

	%case .case-footer-object-container-out {
		position: absolute;
		width: $case-footer-object-container-size;
		height: $case-footer-object-container-size;
		right: 0;
		bottom: 0;
	}

	%case .case-footer-object-positioner {
		position: relative;
		display: inline-block;
		// width: 100%;
		// height: 100%;
	}

	%case .case-footer-object-rotater {
		position: relative;
		display: inline-block;
		width: $case-footer-object-size;
		height: $case-footer-object-size;
		transform: rotate($case-footer-object-ccwrotate);
	}

	%case .case-footer-object-container-in {
		@include object-container($resizer: $case-footer-object-resizer, $curr-displace: $case-footer-object-hover-displace);
		@include transition($transition: $case-footer-object-hover-transition-out);

		&.curr {
			@include transition($transition: $object-hover-transition-in-bounce);
		}
	}

	.mode-light %case .case-footer-object-container-in .object {
		.object-fill-1 { fill: $case-object-fill-1-light; }
		.object-stroke-1 { stroke: $case-object-fill-1-light; }
		.object-fill-2 { fill: $case-object-fill-2-light; }
		.object-stroke-2 { stroke: $case-object-fill-2-light; }
	}
	.mode-dark %case .case-footer-object-container-in .object {
		.object-fill-1 { fill: $case-object-fill-1-dark; }
		.object-stroke-1 { stroke: $case-object-fill-1-dark; }
		.object-fill-2 { fill: $case-object-fill-2-dark; }
		.object-stroke-2 { stroke: $case-object-fill-2-dark; }
	}



	/* Guard */
	$case-guard-top: $spacing-10;
	$case-guard-input-char-num: 8;
	$case-guard-input-char-width: $spacing-8;
	$case-guard-input-char-gap: $spacing-5;
	$case-guard-width: $case-guard-input-char-num * $case-guard-input-char-width + ($case-guard-input-char-num - 1) * $case-guard-input-char-gap;
	$case-guard-height: 116px;
	$case-guard-input-color: $color-xihongshi;
	$case-guard-approved-input-color: $color-lvsongshi;
	$case-guard-placeholder-color-light: $color-gray5-E3;
	$case-guard-placeholder-color-dark: $color-gray2-66;
	$case-guard-underline-height: $border-width-xl;
	$case-guard-underline-color-light: $color-lanlv;
	$case-guard-underline-color-dark: $color-gray1-33;
	$case-guard-underline-curr-blink-time: $time-sec linear $time-sec infinite;

	.case-guard-container {
		width: 100%;
	}

	.case-guard {
		display: block;
		position: relative;
		width: $case-guard-width;
		height: $case-guard-height;
		margin: $case-guard-top auto 0;
		display: flex;
		flex-direction: row;
		justify-content: center;
		@extend %text-display;
		text-transform: uppercase;
	}

	.case-guard-input-back {
		width: $case-guard-width;
		height: 100%;
		opacity: 0;
		caret-color: transparent;
	}

	.case-guard-input-front {
		position: absolute;
		transform: translateY(-50%);
		top: 50%;
		left: 0;
		align-self: stretch;
		display: flex;
		flex-direction: row;
		gap: $case-guard-input-char-gap;
		color: $case-guard-input-color !important;
		pointer-events: none;
	}

	.case-guard-input-front span {
		width: $case-guard-input-char-width;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	.mode-light .case-guard.empty .case-guard-input-front { color: $case-guard-placeholder-color-light !important; }
	.mode-dark .case-guard.empty .case-guard-input-front { color: $case-guard-placeholder-color-dark !important; }

	.case-guard.approved .case-guard-input-front {
		color: $case-guard-approved-input-color !important;
	}

	.case-guard-input-underlines {
		position: absolute;
		bottom: -1 * $case-guard-underline-height;
		left: 0;
		align-self: stretch;
		display: flex;
		flex-direction: row;
		gap: $case-guard-input-char-gap;
		pointer-events: none;
	}

	.case-guard-input-underline {
		content: "";
		width: $case-guard-input-char-width;
		height: $case-guard-underline-height;
		border-radius: $radius-inf;
	}

	.mode-light .case-guard-input-underline { background-color: $case-guard-underline-color-light; }
	.mode-dark .case-guard-input-underline { background-color: $case-guard-underline-color-dark; }

	@keyframes case-guard-underline-blink {
		0% { opacity: 0; }
		49.999% { opacity: 0; }
		50% { opacity: 1; }
		100% { opacity: 1; }
	}
	.case-guard-input-underline.curr {
		animation: case-guard-underline-blink $case-guard-underline-curr-blink-time;
	}



	/* Case Templates */
	.case {
		@extend %case;
	}

	/* Case Template [0] */
	$case-0-header-image-transform: translate(25%,-50%);
	$case-0-header-image-filter: contrast(0.7) grayscale(1) opacity(0.2);
	$case-0-header-object-ccwrotate: 45deg;
	$case-0-header-object-left: -1 * $case-padding + $case-header-object-size / 2;
	$case-0-header-object-bottom: -160px;
	$case-0-header-object-prompt-transform: translate(0,-100%);
	$case-0-header-object-prompt-left: 160px;
	$case-0-header-object-prompt-top: 40px;

	.case.case-0 {

		.case-header-bio-container {
			flex-direction: row-reverse;
		}

		.case-header-img {
			transform: $case-0-header-image-transform;
			top: 50%;
			right: 0;
			left: auto;
			filter: $case-0-header-image-filter;
			pointer-events: none;
		}

		.case-header-object-container-out {
			transform: translate(-50%,50%);
			left: $case-0-header-object-left;
			bottom: $case-0-header-object-bottom;
		}

		.case-header-object-rotater {
			transform: rotate($case-0-header-object-ccwrotate);
		}

		.case-header-object-pillar {
			@include pillarHeightCalc($object-bottom: $case-0-header-object-bottom);
		}

		.case-header-object-prompt {
			transform: $case-0-header-object-prompt-transform;
			left: $case-0-header-object-prompt-left;
			top: $case-0-header-object-prompt-top;
		}
	}
